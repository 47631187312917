import React, { useCallback, useContext, useMemo } from 'react';
import Thumbnail from '@amzn/meridian/thumbnail';
import './AssociateViewTable.css';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import { useRef, useState } from 'react';
import Popover from '@amzn/meridian/popover';
import Icon from '@amzn/meridian/icon';
import errorFilledTokens from '@amzn/meridian-tokens/base/icon/alert-error-small';
import { ShiftAllocationContext } from '../context/ShiftAllocationContext';
import { hasPlacementIssues } from './utils';
import { EMPLOYEE_ICON_COLORS, PROCESS_PATHS } from '../data';
import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout"
import Badge from '@material-ui/core/Badge';

export const EmployeeIcon = ({ boardEntry, associate, assignedProcess, isBenched, header }) => {
  const buttonRef = useRef();
  const [openPopOver, setOpenPopOver] = useState(false);
  const { shiftAllocationDispatch, setAvailableAssociatesVisibility, setIsBoardUpdated } = useContext(ShiftAllocationContext);

  const onClickEmployeeIcon = () => {
    setOpenPopOver(true);
  };

  const onClose = () => {
    setOpenPopOver(false);
  };

  const actualHeader = useMemo(() => {
    return header || assignedProcess;
  }, [header, assignedProcess]);

  const photoUrl = useMemo(() => {
    const regex = /^\d+$/;
    if (regex.test(associate.alias))
      return `https://internal-cdn.amazon.com/badgephotos.amazon.com/?employeeid=${associate.alias}`;
    return `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${associate.alias}`;
  }, [associate]);

  const associatePlacementIssueStatus = useMemo(() => {
    if (!assignedProcess) {
      return {
        hasIssue: false
      };
    }
    return hasPlacementIssues(associate, assignedProcess, boardEntry);
  }, [boardEntry, associate, assignedProcess]);

  const borderStyle = useMemo(() => {
    const style = { border: '2px ' + 'Solid' + ' ' + `${EMPLOYEE_ICON_COLORS.CLOCKED_IN}` };
    if (associatePlacementIssueStatus.hasIssue) {
      style.border = '2px ' + 'Solid' + ' ' + `${EMPLOYEE_ICON_COLORS.ISSUE}`;
    } else if (!associate.clockedInStatus) {
      style.border = '2px ' + 'Dashed' + ' ' + `${EMPLOYEE_ICON_COLORS.CLOCKED_OUT}`;
    }
    return style;
  }, [associate, associatePlacementIssueStatus]);

  const ProcessPathListView = ({ processPathList, title }) => {
    return (
      <Row spacing={'100'}>
        <Column alignmentVertical="top" heights={['fill']}>
          <Text type="h100" style={{ height: '100%' }}>
            {title}:
          </Text>
        </Column>
        <Column>
          <Row wrap="down" spacing="none">
            {processPathList.map((path, index) => (
              <React.Fragment key={path}>
                <Text type="b300" color={'secondary'}>
                  {path}
                </Text>
                {index < processPathList.length - 1 && <Text>, </Text>}
              </React.Fragment>
            ))}
          </Row>
        </Column>
      </Row>
    );
  };

  const findReplacement = useCallback(() => {
    setAvailableAssociatesVisibility(true);
  }, []);

  const remove = useCallback(() => {
    setIsBoardUpdated(true)
    shiftAllocationDispatch({
      type: 'MOVE_ASSOCIATE',
      data: {
        associateAlias: associate.alias,
        from: {
          boardEntry: boardEntry.id,
          processPathId: assignedProcess
        },
        to: {
          boardEntry: PROCESS_PATHS.BENCH
        }
      }
    });
  }, [shiftAllocationDispatch, boardEntry, assignedProcess, associate]);

  return (
    <Column spacing={'none'} width={'60px'}>
      <Row spacing={'none'} alignmentHorizontal={'center'} width={'fit'}>
        <Text transform={'capitalize'} type="B100" truncate={'true'}>
          {actualHeader ? actualHeader : ''}
        </Text>
      </Row>

      <Row spacing={'none'}>
        {associate.thumbnailBadge != null ?
         <div className={'labor-board-employee-icon'} style={borderStyle} onClick={onClickEmployeeIcon} ref={buttonRef}>
          <Badge badgeContent={associate.thumbnailBadge.text} color={associate.thumbnailBadge.color} overlap="circular" anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
          <Thumbnail size="small" source={photoUrl} />
            </Badge>
        </div> :
            <div className={'labor-board-employee-icon'} style={borderStyle} onClick={onClickEmployeeIcon} ref={buttonRef}>
              <Thumbnail size="small" source={photoUrl} />
            </div>
        }
      </Row>
      <Popover anchorNode={buttonRef.current} open={openPopOver} onClose={onClose} position="top">
        <Column spacing={'300'}>
          <Row>
            <Column spacing={'none'} alignmentHorizontal={'start'}>
              <Thumbnail size="small" source={photoUrl} />
            </Column>
            <Column spacing={'none'} alignmentHorizontal={'start'}>
              <Row>
                <Text transform={'capitalize'} type={'h200'}>
                  {associate.name || ''}
                </Text>
              </Row>
              <Row>
                <Text type={'B200'} color={'secondary'}>
                  {`ID: ${associate.alias}`}
                </Text>
              </Row>
            </Column>
            <Column spacing={'none'} alignmentHorizontal={'end'}>
              <Row>
                <Text type={'h200'}>{associate.ppr}</Text>
              </Row>
              <Row>
                <Text type={'B200'} color={'secondary'}>
                  PPR
                </Text>
              </Row>
            </Column>
          </Row>
          <ProcessPathListView processPathList={[assignedProcess]} title={'Assigned'} />
          <ProcessPathListView processPathList={associate.disallowedPaths} title={'Disallowed'} />
          <ProcessPathListView processPathList={associate.trainedPaths} title={'Trained'} />
          <ProcessPathListView processPathList={associate.recommendedPaths} title={'Suggested'} />
          <Row spacing={'200'} alignmentHorizontal={'center'}>
            {associatePlacementIssueStatus.hasIssue && (
              <Text transform={'capitalize'} type="b200" color={'error'}>
                <Icon tokens={errorFilledTokens} /> {associatePlacementIssueStatus.reason}
              </Text>
            )}
          </Row>
          {associate.thumbnailBadge &&
              <Row>
                <div className={'employee-error-popup'} >
              <Icon tokens={infoKnockoutTokens} color="error"/>
              </div>
              <Text>Not Processing packages for last 45 Minutes</Text>

              </Row>
          }
          {!isBenched && (
            <Row alignmentHorizontal="center">
              { associate.thumbnailBadge &&
                  <div className={'employee-error-popup-button'} >
                  <Button onClick={remove} type="link">
                  <Text color="inverted">Track into 5S</Text>
                  </Button>
                  </div>}
              {! associate.thumbnailBadge &&
                  (<Button onClick={remove} type="tertiary">
                    Remove
                  </Button>)   }
              <Button onClick={findReplacement} disabled={true}>Find a replacement</Button>


            </Row>
          )}
        </Column>
      </Popover>

      <Row spacing={'none'} alignmentHorizontal={'center'} width={'fit'}>
        <Text transform={'capitalize'} type="b100" truncate={'true'}>
          {associate.name ? associate.name.toLowerCase() : <span>&nbsp;</span>}
        </Text>
      </Row>
    </Column>
  );
};
