import React, { useState, useEffect, useContext } from 'react';

import CreatePlan from './staticPlan/createPlan/CreatePlan';
import ViewPlan from './ViewPlan';
import ViewPlanSSD from './ViewPlanSSD';
import CreatePlanSSD from './staticPlan/createPlan/CreatePlanSSD';
import LoadingView from '../handler/loadingHandler/LoadingView';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';
import {
  getExperimentMode,
  getIsTest,
  getStationCode,
  getTabLastSelected,
  setTabLastSelected
} from '../utils/networkUtil';
import { chainWalk, changeDateFormat, planTypeFormat } from '../utils/helpers';
import {
  PLAN_STATUS,
  PLANS,
  CALL_API_TIMER,
  PLAN_VERSIONS,
  FEATURES,
  ERROR_CODES,
  SHIFT_PLAN_SUGGESTED_INPUT,
  NODE_TYPE
} from '../utils/constants';
import { GlobalStateContext } from '../global/context/GlobalStateContext';
import apis from '../utils/apis';
import { PermissionsContext } from '../permissions/PermissionsContext';
import { getUserLoginFromParent } from '../handler/parentDataHandler';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';

let nodeId;

const SECONDS_IN_FIFTEEN_MINUTES = 900;

const isValidPlanVersion = (planVersion) => {
  return planVersion !== PLAN_VERSIONS.LABOR_ALLOCATION_V1 && planVersion !== PLAN_VERSIONS.LABOR_ALLOCATION_V2;
};

let responseDataForGivenPlan = {
  NEXT_DAY: null,
  START_SHIFT: null,
  PRE_SHIFT: null
};

let planIdForGivenPlan = {
  NEXT_DAY: '',
  START_SHIFT: '',
  PRE_SHIFT: ''
};

const SCREEN = {
  VIEW_PLAN: 1,
  CREATE_PLAN: 2,
  LOADING: 100
};

const ViewPlanPage = ({
  isSSD,
  //common
  date,
  changeDateHandler,
  tab,
  changeTab,
  planIdForGivenPlan,
  responseDataForGivenPlan,
  setResponseDataForGivenPlan,
  setAsyncError,
  getPlanAPI,
  callAPI,
  updatePlanOutputAndStatus,
  //amzl only
  resetId,
  isDynamicShiftPlanEnabled,
  isUserAuthorisedForShiftPlanOverrides,
  isSsdPendingPlanCreationExists,
  isPlanCancelEnabled,
  cancelledPlanId
}) => {
  if (isSSD) {
    return (
      <ViewPlanSSD
        selectedOfdDate={date}
        onChangeSelectedOfdDate={changeDateHandler}
        selectedPlanType={tab}
        onChangeSelectedPlanType={changeTab}
        responseDataForGivenPlan={responseDataForGivenPlan}
        runPlan={callAPI}
        getPlan={getPlanAPI}
        isSsdPendingPlanCreationExists={isSsdPendingPlanCreationExists}
        isPlanCancelEnabled={isPlanCancelEnabled}
        cancelPlanId={cancelledPlanId}
      />
    );
  }
  return (
    <ViewPlan
      date={date}
      changeDateHandler={changeDateHandler}
      tab={tab}
      setTab={changeTab}
      planIdForGivenPlan={planIdForGivenPlan}
      responseDataForGivenPlan={responseDataForGivenPlan}
      setResponseDataForGivenPlan={setResponseDataForGivenPlan}
      setAsyncError={setAsyncError}
      getPlanAPI={getPlanAPI}
      callAPI={callAPI}
      rerunPlan={callAPI}
      updatePlanOutputAndStatus={updatePlanOutputAndStatus}
      resetId={resetId}
      isDynamicShiftPlanEnabled={isDynamicShiftPlanEnabled}
      isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
    />
  );
};

const CreatePlanPage = ({
  isSSD,
  date,
  data,
  setData,
  responseDataForGivenPlan,
  setResponseDataForGivenPlan,
  setScreen,
  callAPI,
  updatePlanInput,
  updatePlanOutputAndStatus,
  abortPlan,
  isUserAuthorisedForShiftPlanOverrides,
  isFirstLoad,
  setIsFirstLoad
}) => {
  if (isSSD) {
    return <CreatePlanSSD plan={data} setPlan={setData} overridePlan={updatePlanInput} abortPlan={abortPlan} />;
  }
  return (
    <CreatePlan
      date={date}
      data={data}
      setData={setData}
      dataStorage={responseDataForGivenPlan[data.planType]}
      setDataStorage={setResponseDataForGivenPlan}
      setScreen={setScreen}
      callAPI={callAPI}
      updatePlanOutputAndStatus={updatePlanOutputAndStatus}
      isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
      isFirstLoad={isFirstLoad}
      setIsFirstLoad={setIsFirstLoad}
    />
  );
};

const ShiftPlanMainMenu = ({ isSSD }) => {
  const { date, setDate, setAsyncError } = useContext(GlobalStateContext);
  let { isUserAuthorisedForShiftPlanOverrides } = useContext(PermissionsContext);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [screen, setScreen] = useState(1);
  const [data, setData] = useState(null);

  const defaultTab = isSSD ? PLANS.PRE_SHIFT : PLANS.NEXT_DAY;
  const [tab, setTab] = useState(data ? data.planType : defaultTab);

  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [resetId, setResetId] = useState(Math.random());
  const [isDynamicShiftPlanEnabled, setIsDynamicShiftPlanEnabled] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  const [isSsdPendingPlanCreationExists, setIsSsdPendingPlanCreationExists] = useState(false);
  const [isPlanCancelEnabled, setIsPlanCancelEnabled] = useState(false);
  const [cancelledPlanId, setCancelledPlanId] = useState(null);

  const [toasts, setToasts] = useState([]);
  const onCloseToast = (id) => setToasts(toasts.filter((t) => t.id !== id));
  const [currentUser, setCurrentUser] = useState('');
  useEffect(() => {
  }, [currentUser]);

  useEffect(() => {
    nodeId = getStationCode();
    responseDataForGivenPlan = {
      NEXT_DAY: null,
      START_SHIFT: null,
      PRE_SHIFT: null
    };
    planIdForGivenPlan = {
      NEXT_DAY: '',
      START_SHIFT: '',
      PRE_SHIFT: ''
    };

    setLoadingMsg(`Fetching shift plans for date: ${changeDateFormat(date)}... Please wait for few seconds`);
    fetchPlansForGivenDate(date);
    setUpM5Config();
  }, []);

  const changeTab = (val) => {
    setTab(val);
    setResetId(Math.random());

    if (val !== PLANS.DYNAMIC) {
      setIsDataUpdated(false);
      setData(JSON.parse(responseDataForGivenPlan[val]));
    }
    setTabLastSelected(val);
  };

  const fetchPlansForGivenDate = async (selectedDate) => {
    const response = await getUserLoginFromParent();
    const userName = chainWalk(() => response.name.replace(/^"(.*)"$/, '$1'), '');
    setCurrentUser(userName);

    setIsDataUpdated(false);
    setScreen(SCREEN.LOADING);
    let allPlans = await apis['GET_ALL_PLANS'](
      {
        query: {
          date: selectedDate ? selectedDate : date,
          nodeId
        }
      },
      setAsyncError
    );
    let filteredPlans = allPlans['shiftPlans'].filter((plan) => plan.status !== PLAN_STATUS.CANCELLED);
    let allApprovedPlans = filteredPlans.filter(
      (plan) => plan.status === PLAN_STATUS.APPROVED && isValidPlanVersion(plan.planVersion)
    );
    let allPendingApprovalPlans = filteredPlans.filter(
      (plan) => plan.status === PLAN_STATUS.PENDING_APPROVAL && isValidPlanVersion(plan.planVersion)
    ).sort((plan1, plan2) => plan1.createdAt < plan2.createdAt);
    let allPendingCreationPlans = filteredPlans
      .filter(
        (plan) =>
          (plan.status === PLAN_STATUS.PENDING_CREATION || plan.status === PLAN_STATUS.RECEIVED_USER_INPUT) &&
          isValidPlanVersion(plan.planVersion)
      )
      .sort((plan1, plan2) => plan1.createdAt < plan2.createdAt);
    let allClosedPlans = filteredPlans
      .filter((plan) => plan.status === PLAN_STATUS.CLOSED && isValidPlanVersion(plan.planVersion))
      .sort((plan1, plan2) => plan1.createdAt < plan2.createdAt);
    let allPendingUserInputPlans = filteredPlans
      .filter((plan) => plan.status === PLAN_STATUS.PENDING_USER_INPUT && isValidPlanVersion(plan.planVersion))
      .sort((plan1, plan2) => plan1.createdAt < plan2.createdAt);

    if (
      filteredPlans.find(
        (plan) =>
          (plan.status === PLAN_STATUS.PENDING_CREATION || plan.status === PLAN_STATUS.RECEIVED_USER_INPUT) &&
          plan.nodeType === NODE_TYPE.SSD &&
          plan.planVersion === PLAN_VERSIONS.STATIC_V1 &&
          chainWalk(() => Date.now() / 1000 - plan.createdAt, Number.POSITIVE_INFINITY) < SECONDS_IN_FIFTEEN_MINUTES
      )
    ) {
      setIsSsdPendingPlanCreationExists(true);
    } else {
      setIsSsdPendingPlanCreationExists(false);
    }

    let planTypes = Object.values(PLANS).reverse();
    let lastTab = getTabLastSelected();
    for (let planType of planTypes) {
      let pendingApprovalPlan = allPendingApprovalPlans.filter((plan) => plan.planType === planType)[0];
      let approvedPlan = allApprovedPlans.filter((plan) => plan.planType === planType)[0];
      let currentShift = approvedPlan ? approvedPlan : pendingApprovalPlan;
      let shouldFetchCurrentPlan =
        currentShift &&
        (currentShift.status === PLAN_STATUS.APPROVED ||
          (currentShift.status === PLAN_STATUS.PENDING_APPROVAL &&
            currentShift.planVersion === PLAN_VERSIONS.STATIC_V1));
      if (isSSD && userName) {
        let allPendingUserInputPlansForUser = allPendingUserInputPlans
            .filter((plan) => userName === plan.lastUpdatedBy)
            .sort((plan1, plan2) => plan1.createdAt < plan2.createdAt);

        let allPendingUserInputPlansForOtherUsers = allPendingUserInputPlans
            .filter((plan) => userName !== plan.lastUpdatedBy)
            .sort((plan1, plan2) => plan1.createdAt < plan2.createdAt);
        const pendingCreationPlan = allPendingCreationPlans.filter((plan) => plan.planType === planType)[0];
        const pendingUserInputPlan = allPendingUserInputPlansForUser.filter((plan) => plan.planType === planType)[0];
        const pendingUserInputPlanForOtherUsers = allPendingUserInputPlansForOtherUsers.filter((plan) => plan.planType === planType)[0];
        const closedPlan = allClosedPlans.filter((plan) => plan.planType === planType)[0];
        if (
          chainWalk(() => Date.now() / 1000 - pendingCreationPlan.createdAt, Number.POSITIVE_INFINITY) <
          SECONDS_IN_FIFTEEN_MINUTES
        ) {
          setIsPlanCancelEnabled(false);
          setToasts(
            toasts.concat({
              id: Math.random(),
              message: `A new plan (Id: ${pendingCreationPlan.planId}) is being created by ${pendingCreationPlan.lastUpdatedBy}...`,
              showCancelButton: false,
              planId: pendingCreationPlan.planId
            })
          );
        }
        if (
            chainWalk(() => Date.now() / 1000 - pendingUserInputPlanForOtherUsers.createdAt, Number.POSITIVE_INFINITY) <
            SECONDS_IN_FIFTEEN_MINUTES
        ) {
          setIsPlanCancelEnabled(true);
          setCancelledPlanId(pendingUserInputPlanForOtherUsers.planId);
          setToasts(
              toasts.concat({
                id: Math.random(),
                message: `A new plan (Id: ${pendingUserInputPlanForOtherUsers.planId}) is being created by user ${pendingUserInputPlanForOtherUsers.lastUpdatedBy}`,
                showCancelButton: false,
                planId: pendingUserInputPlanForOtherUsers.planId,
                timeout: 5000
              })
          );
        }
        currentShift = pendingUserInputPlan
          ? pendingUserInputPlan
          : approvedPlan
          ? approvedPlan
          : pendingApprovalPlan
          ? pendingApprovalPlan
          : closedPlan;
        shouldFetchCurrentPlan = !!currentShift;
      }

      planIdForGivenPlan[planType] = currentShift ? currentShift.planId : planIdForGivenPlan[planType];
      if (shouldFetchCurrentPlan) {
        let res = await apis['GET_PLAN'](
          {
            query: {
              planId: planIdForGivenPlan[planType]
            }
          },
          setAsyncError
        );
        responseDataForGivenPlan[planType] = JSON.stringify(res);
        changeTab(planType);
      }
    }
    if (!!lastTab) {
      changeTab(lastTab);
    }
    setScreen(SCREEN.VIEW_PLAN);
  };

  const changeDateHandler = async (val) => {
    setDate(val);
    setData(null);
    const defaultTab = isSSD ? PLANS.PRE_SHIFT : PLANS.NEXT_DAY;
    setTab(data ? data.planType : defaultTab);
    setIsDataUpdated(false);
    setLoadingMsg(`Fetching shift plans for date: ${changeDateFormat(val)}... Please wait for few seconds`);

    responseDataForGivenPlan = {
      NEXT_DAY: null,
      START_SHIFT: null,
      PRE_SHIFT: null
    };

    planIdForGivenPlan = {
      NEXT_DAY: '',
      START_SHIFT: '',
      PRE_SHIFT: ''
    };

    await fetchPlansForGivenDate(val);
  };

  const getPlanAPI = async (planType) => {
    setLoadingMsg(`Fetching shift plan of plan type: ${planTypeFormat(planType)}... Please wait for few seconds`);
    setScreen(SCREEN.LOADING);
    let res = await apis['GET_PLAN'](
      {
        query: {
          planId: planIdForGivenPlan[planType]
        }
      },
      setAsyncError
    );
    responseDataForGivenPlan[planType] = JSON.stringify(res);
    if (!isSSD) {
      const closedPlan = await getClosedPlan(planType);
      if (closedPlan) {
        res.input =
          SHIFT_PLAN_SUGGESTED_INPUT in res.input
            ? { ...closedPlan.input, [SHIFT_PLAN_SUGGESTED_INPUT]: res.input[SHIFT_PLAN_SUGGESTED_INPUT] }
            : closedPlan.input;
        res.output = closedPlan.output;
      }
    }
    setData(res);
    setIsFirstLoad(true);
    setScreen(SCREEN.CREATE_PLAN);
  };

  const getClosedPlan = async (planType) => {
    let allPlans = await apis['GET_ALL_PLANS'](
      {
        query: {
          date,
          nodeId
        }
      },
      setAsyncError
    );
    let allClosedPlans = allPlans['shiftPlans'].filter((plan) => plan.status === PLAN_STATUS.CLOSED);
    let closedPlan = allClosedPlans
      .filter((plan) => plan.planType === planType)
      .sort((a, b) => b.updatedAt - a.updatedAt)[0];
    let res = null;
    if (closedPlan) {
      res = await apis['GET_PLAN'](
        {
          query: {
            planId: closedPlan.planId
          }
        },
        setAsyncError
      );
    }
    if (res && res.input != null) {
      return res;
    }
    return null;
  };

  const callAPI = async (
    planType,
    updatedData,
    onGeneratePendingApprovalPlanSucceed,
    { shiftPlanHorizonStartTime, shiftPlanHorizonEndTime } = {}
  ) => {
    setLoadingMsg(`${updatedData ? 'Your plan is now updating' : 'Generating a new plan'}. Please wait.`);
    setScreen(SCREEN.LOADING);
    if (updatedData) {
      setIsDataUpdated(true);
    }
    let planIdCall = await apis['CREATE_PLAN'](
      {
        body: {
          nodeId,
          ofdDate: date,
          planType,
          planVersion: getIsTest() ? PLAN_VERSIONS.STATIC_V2_BETA : PLAN_VERSIONS.STATIC_V1,
          experimentMode: getExperimentMode(),
          input: updatedData ? updatedData.input : undefined,
          output: updatedData ? updatedData.output : undefined,
          shiftPlanHorizonStartTime: shiftPlanHorizonStartTime ? shiftPlanHorizonStartTime : undefined,
          shiftPlanHorizonEndTime: shiftPlanHorizonEndTime ? shiftPlanHorizonEndTime : undefined
        }
      },
      () => setAsyncError(ERROR_CODES.SHIFT_PLAN_ALREADY_CREATED)
    );
    planIdForGivenPlan[planType] = planIdCall.planId;
    let timeout = 48; // Timeout after 12 minutes
    let timer = setInterval(async () => {
      timeout--;
      console.log('fetching data again...');
      let res = await apis['GET_PLAN'](
        {
          query: {
            planId: planIdForGivenPlan[planType]
          }
        },
        setAsyncError
      );
      if ([PLAN_STATUS.PENDING_APPROVAL, PLAN_STATUS.PENDING_USER_INPUT].includes(res.status)) {
        console.log('data received...');
        clearInterval(timer);
        responseDataForGivenPlan[planType] = JSON.stringify(res);
        if (!isSSD && !updatedData) {
          let closedPlan = await getClosedPlan(planType);
          if (closedPlan) {
            res.input = closedPlan.input;
            res.output = closedPlan.output;
          }
        }
        setData(res);
        setScreen(SCREEN.CREATE_PLAN);
        onGeneratePendingApprovalPlanSucceed && onGeneratePendingApprovalPlanSucceed();
      } else if (res.status === PLAN_STATUS.FAILED) {
        clearInterval(timer);
        var title =
          res.planId && res.errorMessage && res.errorCode
            ? `Error creating shift plan ${res.planId} \ Error Message: ${res.errorMessage} \ Error Code: ${res.errorCode}`
            : 'Error Message: Failed to create shift plan  Error Code: STATIC_SHIFT_PLANNING_EXCEPTION';
        setAsyncError(title);
      } else if (timeout <= 0) {
        clearInterval(timer);
        setAsyncError('Shift plan generation timed out. Please try again.');
      }
    }, CALL_API_TIMER);
  };

  const abortPlan = async () => {
    await updatePlanStatus(PLAN_STATUS.CANCELLED, tab);
    responseDataForGivenPlan = {
      NEXT_DAY: null,
      START_SHIFT: null,
      PRE_SHIFT: null
    };
    planIdForGivenPlan = {
      NEXT_DAY: '',
      START_SHIFT: '',
      PRE_SHIFT: ''
    };
    fetchPlansForGivenDate(date);
  };

  const abortOtherUserPlan = async (planId, toast) => {
    await updatePlanStatusForPlan(PLAN_STATUS.CANCELLED, planId);
    onCloseToast(toast.id);
  };

  const updatePlanStatusForPlan = async (status, planId) => {
    await apis['UPDATE_PLAN_STATUS'](
        {
          body: {
            planId: planId,
            status
          }
        },
        setAsyncError
    );
    if (status === PLAN_STATUS.APPROVED) {
      setScreen(SCREEN.VIEW_PLAN);
    }
  };

  const updatePlanStatus = async (status, planType) => {
    await apis['UPDATE_PLAN_STATUS'](
      {
        body: {
          planId: planIdForGivenPlan[planType],
          status
        }
      },
      setAsyncError
    );
    if (status === PLAN_STATUS.APPROVED) {
      setScreen(SCREEN.VIEW_PLAN);
    }
  };

  const updatePlanInput = async (planId, updatedInput) => {
    setLoadingMsg('Your plan is now generating, please wait...');
    setScreen(SCREEN.LOADING);
    await apis['UPDATE_INPUT'](
      {
        body: {
          planId,
          input: updatedInput
        }
      },
      () => setAsyncError(ERROR_CODES.SHIFT_PLAN_UPDATE_ERROR)
    );
    let timeout = 48; // Timeout after 12 minutes
    let timer = setInterval(async () => {
      timeout--;
      console.log('fetching data again...');
      let plan = await apis['GET_PLAN'](
        {
          query: {
            planId
          }
        },
        setAsyncError
      );
      if (plan.status === PLAN_STATUS.PENDING_APPROVAL) {
        console.log('data received...');
        clearInterval(timer);
        responseDataForGivenPlan[plan.type] = JSON.stringify(plan);
        fetchPlansForGivenDate(date);
      } else if (plan.status === PLAN_STATUS.FAILED) {
        clearInterval(timer);
        const title =
          plan.errorMessage && plan.errorCode
            ? `Error creating shift plan ${planId} \ Error Message: ${plan.errorMessage} \ Error Code: ${plan.errorCode}`
            : 'Error Message: Failed to create shift plan Error Code: STATIC_SHIFT_PLANNING_EXCEPTION';
        setAsyncError(title);
      } else if (timeout <= 0) {
        clearInterval(timer);
        setAsyncError('Shift plan generation timed out. Please try again.');
      }
    }, CALL_API_TIMER);
  };

  const updatePlanOutputAndStatus = async (planId, planType, updatedOutput) => {
    setLoadingMsg('Your plan is now updating. This can take up to 5 minutes to run. Please wait and do not refresh.');
    setScreen(SCREEN.LOADING);
    if (updatedOutput) {
      await apis['UPDATE_OUTPUT'](
        {
          body: {
            planId,
            output: updatedOutput
          }
        },
        setAsyncError
      );
    }
    await updatePlanStatus(PLAN_STATUS.APPROVED, planType);
  };

  const setResponseDataForGivenPlan = (planType, data) => {
    responseDataForGivenPlan[planType] = JSON.stringify(data);
  };

  const setUpM5Config = async () => {
    let res = await apis.callFeaturesAPI([FEATURES.IS_DYNAMIC_SHIFT_PLAN], setAsyncError);
    if (chainWalk(() => res[FEATURES.IS_DYNAMIC_SHIFT_PLAN] === true, false)) {
      setIsDynamicShiftPlanEnabled(true);
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <React.Fragment>
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="warning">
              <div>{toast.message}</div>
              {toast.showCancelButton &&
                  <Row alignmentHorizontal={'center'}>
                    <Button type="primary" size="small"
                            onClick={() => abortOtherUserPlan(toast.planId, toast)}>Cancel Plan</Button>

                  </Row>}
            </Alert>
          )}
        </Toaster>
      </React.Fragment>
      {screen === 1 ? (
        <ViewPlanPage
          isSSD={isSSD}
          date={date}
          changeDateHandler={changeDateHandler}
          tab={tab}
          changeTab={changeTab}
          planIdForGivenPlan={planIdForGivenPlan}
          responseDataForGivenPlan={responseDataForGivenPlan}
          setResponseDataForGivenPlan={setResponseDataForGivenPlan}
          setAsyncError={setAsyncError}
          getPlanAPI={getPlanAPI}
          callAPI={callAPI}
          updatePlanOutputAndStatus={updatePlanOutputAndStatus}
          resetId={resetId}
          isDynamicShiftPlanEnabled={isDynamicShiftPlanEnabled}
          isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          isSsdPendingPlanCreationExists={isSsdPendingPlanCreationExists}
          isPlanCancelEnabled={isPlanCancelEnabled}
          cancelledPlanId={cancelledPlanId}
          toasts={toasts}
        />
      ) : screen === 2 ? (
        <CreatePlanPage
          isSSD={isSSD}
          date={date}
          data={data}
          setData={setData}
          responseDataForGivenPlan={responseDataForGivenPlan}
          setResponseDataForGivenPlan={setResponseDataForGivenPlan}
          setScreen={setScreen}
          callAPI={callAPI}
          updatePlanInput={updatePlanInput}
          updatePlanOutputAndStatus={updatePlanOutputAndStatus}
          abortPlan={abortPlan}
          isUserAuthorisedForShiftPlanOverrides={isUserAuthorisedForShiftPlanOverrides}
          isFirstLoad={isFirstLoad}
          setIsFirstLoad={setIsFirstLoad}
        />
      ) : (
        <LoadingView loadingMsg={loadingMsg} />
      )}
    </div>
  );
};

export default ShiftPlanMainMenu;
