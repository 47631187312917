import React from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { EmployeeIcon } from './EmployeeIcon';
import { SHIFT_ALLOCATION_ID_PREFIX, SHIFT_ALLOCATION_ID_SUFFIX } from '../../utils/constants';
import { EmptyEmployeeIcon } from './EmptyEmployeeIcon';
import styles from './styles.module.css';
import { clusterAssignmentHeaderFormatter, getHeadcountForProcessPaths, getProcessCardHeaderColor } from './utils';
import { ALLOCATION_TYPE, PROCESS_PATHS, PROCESS_TYPE, TRAINING_ALLOCATION_TYPES } from '../data';

const EXCLUSIVE_PROCESS_DETAIL_CARDS = [PROCESS_PATHS.TRAINEE, PROCESS_PATHS.YARD_MARSHALL];

const validProcessToRenderProcessDetailCard = (renderList, boardEntry) => {
  return (
    renderList.length === 0 &&
    boardEntry.processPathEntries.length > 0 &&
    !EXCLUSIVE_PROCESS_DETAIL_CARDS.includes(boardEntry.title)
  );
};

export const ProcessDetailCard = ({ boardEntry, associateInfo }) => {
  const renderProcessDetailCard = () => {
    const renderList = boardEntry.assignments.map((assignment, index) => {
      return (
        <Draggable
          // adding a key is important!
          key={assignment.resourceId}
          draggableId={assignment.resourceId}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              id={`${SHIFT_ALLOCATION_ID_PREFIX.DRAGGABLE_ASSOCIATE}-${assignment.resourceId}`}
              style={{ ...provided.draggableProps.style, position: snapshot.isDragging ? 'absolute' : 'static' }}
            >
              <EmployeeIcon
                boardEntry={boardEntry}
                associate={associateInfo[assignment.resourceId]}
                assignedProcess={assignment.allocationAttrs.process}
                header={
                  assignment.allocationAttrs.type === ALLOCATION_TYPE.CLUSTERS
                    ? clusterAssignmentHeaderFormatter(assignment)
                    : null
                }
              />
            </div>
          )}
        </Draggable>
      );
    });

    getHeadcountForProcessPaths(boardEntry).forEach((headCount, index) => {
      if (headCount.actualHeadcount < headCount.required) {
        const missing = headCount.required - headCount.actualHeadcount;
        for (let i = 0; i < missing; i++) {
          const droppableId = `${boardEntry.id}-${headCount.process}-${SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP}-${SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD}-${i}`;
          renderList.push(
            <Droppable
              droppableId={droppableId}
              type={'PROCESS_PATH'}
              key={`${boardEntry.id}-${headCount.process}-${i}`}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  id={`${SHIFT_ALLOCATION_ID_PREFIX.EMPTY_ASSOCIATE_PREFIX}-${droppableId}`}
                >
                  <EmptyEmployeeIcon
                    header={headCount.process}
                    boardEntry={boardEntry.id}
                    containerType={SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}
                    processPathId={headCount.process}
                    droppableId={droppableId}
                    placeHolder={provided.placeholder}
                  />
                </div>
              )}
            </Droppable>
          );
        }
      }
    });

    if (validProcessToRenderProcessDetailCard(renderList, boardEntry)) {
      const processPathId = boardEntry.processPathEntries[0].process;
      const droppableId = `${boardEntry.id}-${processPathId}-${SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP}-${
        SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD
      }-${0}`;
      renderList.push(
        <Droppable droppableId={droppableId} type={'PROCESS_PATH'} key={`${boardEntry.id}-${processPathId}-${0}`}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={`${SHIFT_ALLOCATION_ID_PREFIX.EMPTY_ASSOCIATE_PREFIX}-${droppableId}`}
            >
              <EmptyEmployeeIcon
                header={processPathId}
                boardEntry={boardEntry.id}
                containerType={SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}
                processPathId={processPathId}
                droppableId={droppableId}
                placeHolder={provided.placeholder}
              />
            </div>
          )}
        </Droppable>
      );
    }

    // special case for Trainee and Yard Marshall
    if (EXCLUSIVE_PROCESS_DETAIL_CARDS.includes(boardEntry.title)) {
        const processNames = boardEntry.processPathEntries.map(entry => entry.process);
        processNames.forEach(
        (process) => {
          const processPathId = process;
          const droppableId = `${boardEntry.id}-${processPathId}-${SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP}-${
            SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD
          }-${0}`;
          renderList.push(
            <Droppable droppableId={droppableId} type={'PROCESS_PATH'} key={`${boardEntry.id}-${processPathId}-${0}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  id={`${SHIFT_ALLOCATION_ID_PREFIX.EMPTY_ASSOCIATE_PREFIX}-${droppableId}`}
                >
                  <EmptyEmployeeIcon
                    header={processPathId}
                    boardEntry={boardEntry.id}
                    containerType={SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}
                    processPathId={processPathId}
                    droppableId={droppableId}
                    placeHolder={provided.placeholder}
                  />
                </div>
              )}
            </Droppable>
          );
        }
      );
    }
    return renderList;
  };

  return (
    <Column spacingInset={'none'} spacing="none" id={'test_row'} type={'outline'}>
      <div
        style={{
          backgroundColor: getProcessCardHeaderColor(boardEntry, associateInfo),
          minHeight: '20px',
          alignmentHorizontal: 'center'
        }}
      >
        <Text color="inverted" alignment="center" className="clusterDetailCardTitle" type={'h100'}>
          {boardEntry.title}
        </Text>
      </div>
      <Droppable
        droppableId={`${boardEntry.id}-${SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD}`}
        type={'PROCESS_PATH'}
        key={boardEntry.id}
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={{ width: '100%' }} {...provided.droppableProps}>
            <Row
              alignmentHorizontal={'start'}
              spacingInset={'small'}
              wrap={'down'}
              spacing="xsmall"
              widths={['grid-4']}
              id={`${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}-${boardEntry.id}-${SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD}`}
            >
              {renderProcessDetailCard()}
            </Row>
          </div>
        )}
      </Droppable>
    </Column>
  );
};
