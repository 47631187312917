import { isValidDragAndDrop } from '../../utils/dragAndDropHelper';
import { MOVE_ASSOCIATE, SET_MODEL } from '../action/ShiftAllocationAction';
import { ALLOCATION_TYPE, getTimeWindowAllocationKey, PROCESS_PATHS, PROCESS_TYPE } from '../data';

export const isDroppedInSingleOptionProcess = (result, boardEntries) => {
  const boardEntryId = result.destination.droppableId.split('-')[0];

  const boardEntry = boardEntries.find((entry) => entry.id === boardEntryId);
  if (isToProcessAisleAssigned(boardEntry)) {
    return false;
  }
  if (boardEntry && boardEntry.processPathEntries.length === 1) {
    return boardEntry.processPathEntries[0].process;
  } else {
    return false;
  }
};

const getProcessInfoByResourceId = (processInfo, resourceId) => {
  if (resourceId && processInfo) {
    for (const processObjKey of Object.keys(processInfo)) {
      const processObj = processInfo[processObjKey];
      for (const assignment of processObj.assignments) {
        if (assignment && assignment.resourceId === resourceId) {
          return processObj;
        }
      }
    }
  }
  return null;
};

export const upsertInductOrAslLines = (linesToUpsert, adminSettings, shiftAllocationModel, shiftAllocationDispatch) => {
  let newLines = [];
  let newBoardEntries = []

  const boardConfig = adminSettings['AMZL']['boardConfig'];
  const processInfo = shiftAllocationModel['processInfo'];
  const boardEntries = shiftAllocationModel['boardEntries'];

  linesToUpsert.forEach(({ process, lines }) => {
    const numberOfNewLines = lines;
    const processBoardConfig = boardConfig.filter((config) => config.title === process);

    if (processBoardConfig && processBoardConfig.length > 0) {
      processBoardConfig.forEach((config) => {
        if (config.processPathEntries && config.processPathEntries.length > 0) {
          config.processPathEntries = config.processPathEntries.map((entry) => ({
            ...entry,
            minResources: 1
          }));
        }
      });

      const { allocationType, processPathEntries, type, title } = processBoardConfig[0];

      const numberOfExistingLines = processInfo.filter((info) => info['title'].includes(process)).length;

      for (let i = numberOfExistingLines + 1; i <= numberOfExistingLines + numberOfNewLines; i++) {
        const id = `${title.toLowerCase()}_${i}`;
        const updatedTitle = `${title} ${i}`;

        newLines.push({
          allocationType,
          id,
          processPathEntries,
          type,
          assignments: [],
          title: updatedTitle
        });

        newBoardEntries.push({
          allocationType,
          id,
          processPathEntries,
          type,
          title: updatedTitle
        });
      }
    }
  });

  const updatedProcessInfo = [...processInfo, ...newLines];

  const updatedBoardEntries = [...boardEntries, ...newBoardEntries];

  shiftAllocationDispatch({
    type: SET_MODEL,
    payload: {
      ...shiftAllocationModel,
      processInfo: updatedProcessInfo,
      boardEntries: updatedBoardEntries
    }
  });
};

export const moveClockedOutAllocationsToBench = (shiftAllocationModel, shiftAllocationDispatch) => {
  const associateInfo = shiftAllocationModel['associateInfo'];
  const processInfo = shiftAllocationModel['processInfo'];

  const filterClockedOutAssociates = Object.values(associateInfo).filter(
    (process) => process.clockedInStatus === false
  );

  if (filterClockedOutAssociates) {
    for (const associate of filterClockedOutAssociates) {
      const associateProcessInfo = getProcessInfoByResourceId(processInfo, associate.associateId);

      if (associateProcessInfo) {
        const assignments = associateProcessInfo.assignments.filter((obj) => obj.resourceId === associate.associateId);

        shiftAllocationDispatch({
          type: MOVE_ASSOCIATE,
          data: {
            associateAlias: associate.associateId,
            from: {
              boardEntry: associateProcessInfo.id,
              processPathId: assignments[0].allocationAttrs.process
            },
            to: {
              boardEntry: PROCESS_PATHS.BENCH
            }
          }
        });
      }
    }
    return true;
  }
  return false;
};

export const isDroppedInBench = (result) => {
  const boardEntryId = result.destination.droppableId.split('-')[0];

  return boardEntryId === PROCESS_PATHS.BENCH;
};

export function moveASsociateToBench(result, provided, shiftAllocationDispatch) {
  if (isValidDragAndDrop(result, provided)) {
    const fromProcessPath = result.source.droppableId.split('-')[0];
    shiftAllocationDispatch({
      type: MOVE_ASSOCIATE,
      data: {
        associateAlias: result.draggableId,
        to: {
          boardEntry: PROCESS_PATHS.BENCH,
          processPath: PROCESS_PATHS.BENCH
        },
        from: {
          boardEntry: fromProcessPath
        }
      }
    });
  }
}

const isToProcessAisleAssigned = (toBoardEntry) => {
  return (
    toBoardEntry.allocationType === ALLOCATION_TYPE.AISLES ||
    toBoardEntry.id.includes(PROCESS_PATHS.PICK_TO_BUFFER) ||
    toBoardEntry.id.includes(PROCESS_PATHS.STOW) ||
    toBoardEntry.id.includes(PROCESS_PATHS.ADTA_STOW)
  );
};

export function moveAssociateToRecommendedSpot(result, provided, shiftAllocationDispatch) {
  if (isValidDragAndDrop(result, provided)) {
    const toProcessPath = result.destination.droppableId.split('-')[0];
    const toProcessDetailId = result.destination.droppableId.split('-')[1];
    const fromProcessPath = result.source.droppableId.split('-')[0];
    const fromProcessDetailId = result.source.droppableId.split('-')[1];
    if (
      result.destination.droppableId.includes(PROCESS_PATHS.PICK_TO_BUFFER) ||
      result.destination.droppableId.includes(PROCESS_PATHS.STOW) ||
      result.destination.droppableId.includes(PROCESS_PATHS.ADTA_STOW)
    ) {
      const minAisle = result.destination.droppableId.split('-')[2];
      const maxAisle = result.destination.droppableId.split('-')[3];
      shiftAllocationDispatch({
        type: MOVE_ASSOCIATE,
        data: {
          associateAlias: result.draggableId,
          to: {
            boardEntry: toProcessPath,
            processPath: toProcessDetailId,
            minAisle: Number(minAisle),
            maxAisle: Number(maxAisle)
          },
          from: {
            boardEntry: fromProcessPath
          }
        }
      });
    } else {
      shiftAllocationDispatch({
        type: MOVE_ASSOCIATE,
        data: {
          associateAlias: result.draggableId,
          to: {
            boardEntry: toProcessPath,
            processPath: toProcessDetailId
          },
          from: {
            boardEntry: fromProcessPath
          }
        }
      });
    }
  }
}

export const moveAssociateToSingleOptionProcess = (result, singleProcessDrop, shiftAllocationDispatch) => {
  const toProcessPath = result.destination.droppableId.split('-')[0];
  const fromProcessPath = result.source.droppableId.split('-')[0];

  shiftAllocationDispatch({
    type: MOVE_ASSOCIATE,
    data: {
      associateAlias: result.draggableId,
      to: {
        boardEntry: toProcessPath,
        processPath: singleProcessDrop
      },
      from: {
        boardEntry: fromProcessPath
      }
    }
  });
};
