import { chainWalk } from '../../../../utils/helpers';

export const getSinglesVolume = (outboundVolume, singlesEnabled) => {
  const singlesPlanningChargeOverride = chainWalk(
    () => outboundVolume.overrides.singles.overrideKeyValueMap.singlesPlanningCharge,
    null
  );
  const singlesPredictedCharge = singlesEnabled ? outboundVolume['singlesPredictedCharge'] : 0;
  const singlesPlanningCharge = singlesEnabled
    ? singlesPlanningChargeOverride == null
      ? singlesPredictedCharge
      : parseFloat(singlesPlanningChargeOverride || 0)
    : 0;
  const singlesRodeoBacklog = singlesEnabled ? outboundVolume['rodeoSinglesBacklog'] : 0;
  return { singlesPredictedCharge, singlesPlanningCharge, singlesRodeoBacklog };
};

export const getVnaVolume = (outboundVolume) => {
  const vnaPredicatedCharge = outboundVolume['vnaPredictedCharge'];
  const vnaRodeoBacklog = outboundVolume['rodeoVNABacklog'];
  return { vnaPredicatedCharge, vnaRodeoBacklog };
};

export const getPick2RebinVolume = (outboundVolume, singlesEnabled) => {
  const { vnaPredicatedCharge } = getVnaVolume(outboundVolume);
  const { singlesPlanningCharge } = getSinglesVolume(outboundVolume, singlesEnabled);
  const atropsOverrideCapacityPercentage = chainWalk(
    () => outboundVolume.overrides.pick2rebin.overrideKeyValueMap.atropsOverrideCapacityPercentage,
    null
  );
  let pickToRebinPredictedCharge = outboundVolume['pickToRebinPredictedCharge'];
  let pickToRebinMaxPotentialCharge = Math.max(
    outboundVolume['atropsCapacity'] - outboundVolume['atropsScheduled'] - vnaPredicatedCharge - singlesPlanningCharge,
    0
  );
  let pickToRebinPlanningCharge = Math.min(pickToRebinPredictedCharge, pickToRebinMaxPotentialCharge);
  pickToRebinPlanningCharge =
    atropsOverrideCapacityPercentage == null
      ? pickToRebinPlanningCharge
      : getPick2RebinOverridePlanningCharge(outboundVolume, atropsOverrideCapacityPercentage, singlesEnabled);
  let pickToRebinRodeoBacklog = outboundVolume['rodeoPickToRebinBacklog'];

  if (outboundVolume.overrides && outboundVolume.overrides.pick2rebin && atropsOverrideCapacityPercentage !== null) {
  outboundVolume.overrides.pick2rebin.overrideKeyValueMap['atropsOverrideCapacityValue'] = pickToRebinPlanningCharge + '';
  }
  return {
    pickToRebinPredictedCharge,
    pickToRebinMaxPotentialCharge,
    pickToRebinPlanningCharge,
    pickToRebinRodeoBacklog
  };
};

export const getPick2RebinOverridePlanningCharge = (
  outboundVolume,
  atropsOverrideCapacityPercentage,
  singlesEnabled
) => {
  const { vnaPredicatedCharge } = getVnaVolume(outboundVolume);
  const { singlesPlanningCharge } = getSinglesVolume(outboundVolume, singlesEnabled);
  return Math.max(
    Math.round((parseFloat(atropsOverrideCapacityPercentage || 0) * outboundVolume['atropsCapacity']) / 100) -
      outboundVolume['atropsScheduled'] -
      vnaPredicatedCharge -
      singlesPlanningCharge,
    0
  );
};

export const getSortableVolume = (outboundVolume, singlesEnabled) => {
  const { singlesPredictedCharge, singlesPlanningCharge } = getSinglesVolume(outboundVolume, singlesEnabled);
  const { pickToRebinPredictedCharge, pickToRebinMaxPotentialCharge, pickToRebinPlanningCharge } = getPick2RebinVolume(
    outboundVolume,
    singlesEnabled
  );
  let sortablePredictedCharge = pickToRebinPredictedCharge + singlesPredictedCharge;
  let sortableMaxPotentialCharge = pickToRebinMaxPotentialCharge + singlesPlanningCharge;
  let sortablePlanningCharge = pickToRebinPlanningCharge + singlesPlanningCharge;
  return { sortablePredictedCharge, sortableMaxPotentialCharge, sortablePlanningCharge };
};

export const getTotalVolume = (outboundVolume, singlesEnabled) => {
  const { vnaPredicatedCharge, vnaRodeoBacklog } = getVnaVolume(outboundVolume);
  const { singlesRodeoBacklog } = getSinglesVolume(outboundVolume, singlesEnabled);
  const { pickToRebinRodeoBacklog } = getPick2RebinVolume(outboundVolume, singlesEnabled);
  const { sortablePlanningCharge } = getSortableVolume(outboundVolume, singlesEnabled);
  let totalRodeoBacklog = pickToRebinRodeoBacklog + vnaRodeoBacklog + singlesRodeoBacklog;
  let totalPlanningVolume = totalRodeoBacklog + sortablePlanningCharge + vnaPredicatedCharge;
  return { totalRodeoBacklog, totalPlanningVolume };
};

export const getSinglesOverrideGuardrails = (outboundVolume) => {
  const { sortableMaxPotentialCharge } = getSortableVolume(outboundVolume, true);
  const { pickToRebinPlanningCharge } = getPick2RebinVolume(outboundVolume, true);
  const minVolume = 0;
  const maxVolume = Math.floor(sortableMaxPotentialCharge - pickToRebinPlanningCharge * 0.75);
  return { minVolume, maxVolume };
};
