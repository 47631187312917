import React from 'react';
import Row from '@amzn/meridian/row';
import { EMPLOYEE_ICON_COLORS } from '../data.js';

export const ProcessViewLegend = ({}) => {
  const Legend = ({ border, backgroundColor = null, shape = 'circle' }) => {
    const style = {
      width: '20px',
      height: '20px',
      border: `${border}`,
      borderRadius: shape === 'square' ? '0' : '50%',
      backgroundColor: backgroundColor || 'transparent'
    };

    return <div style={style}></div>;
  };

  const Title = ({ color, text }) => {
    const titleStyle = {
      fontSize: '15px',
      color: `${color}`,
      font: 'bookerly'
    };

    return <div style={titleStyle}>{text}</div>;
  };

  return (
    <Row spacing={'500'} spacingInset={'200'} alignmentHorizontal="center">
      <Row spacing={'200'}>
        <Legend border={`3px solid ${EMPLOYEE_ICON_COLORS.CLOCKED_IN}`}></Legend>
        <Title text={'Clocked In'} color={'#030303'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={`2px dashed ${EMPLOYEE_ICON_COLORS.CLOCKED_OUT}`}></Legend>
        <Title text={'Not Clocked In'} color={'#030303'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={`2px dashed ${EMPLOYEE_ICON_COLORS.RECOMMENDED}`}></Legend>
        <Title text={'Recommended'} color={'#030303'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={`2px solid ${EMPLOYEE_ICON_COLORS.ISSUE}`}></Legend>
        <Title text={'Issue'} color={'#030303'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={'2px solid #6E777F'} shape={'square'} backgroundColor={'#FEF2B8'}></Legend>
        <Title text={' 25% Under Staffed'} color={'#030303'} />
      </Row>
      <Row spacing={'200'}>
        <Legend border={'2px solid #6E777F'} shape={'square'} backgroundColor={'#FFCCE5'}></Legend>
        <Title text={' 50% Over Staffed'} color={'#030303'} />
      </Row>
    </Row>
  );
};
