import React from 'react';

export const CALL_API_TIMER = 15000;
export const M5_RELOAD_PAGE_TIME_OUT = 5 * 60000; // 5 minutes in ms
export const ANONYMOUS_ASSOCIATE_NAME = 'Anonymous';
export const SECONDS_IN_AN_HOUR = 3600;
export const EU = 'eu';
export const JP = 'jp';
export const ALL_CYCLES = 'All cycles';
export const TABLE_METRICS = new Map([
  // [<variable name>, <display name>]
  ['requiredHeadcount', 'Headcount'],
  ['hours', 'Hours'],
  ['volume', 'Volume'],
  ['rate', 'Rate']
]);
export const PLANS = {
  NEXT_DAY: 'NEXT_DAY',
  PRE_SHIFT: 'PRE_SHIFT',
  START_SHIFT: 'START_SHIFT',
  DYNAMIC: 'DYNAMIC'
};

export const DARK_MODE = {
  DARK: 'dark',
  LIGHT: 'light'
};

export const LABOR_TRACKING_STATUS = {
  ELIGIBLE: 'ELIGIBLE',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
  PARTIAL_SUCCESSFUL: 'PARTIAL_SUCCESSFUL',
  NO_UPDATES_TO_TRACK: 'NO_UPDATES_TO_TRACK'
};

export const PATH_TYPE = {
  INDIRECT: 'INDIRECT',
  DIRECT: 'DIRECT',
  MASTER: 'MASTER'
};

export const PLAN_STATUS = {
  PENDING_CREATION: 'PENDING_CREATION',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  CLOSED: 'CLOSED',
  APPROVED: 'APPROVED',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
  PENDING_USER_INPUT: 'PENDING_USER_INPUT',
  RECEIVED_USER_INPUT: 'RECEIVED_USER_INPUT',
  CANCELLED: 'CANCELLED'
};

export const PLAN_VERSIONS = {
  STATIC_V1: 'STATIC_V1',
  STATIC_V2_BETA: 'EXPERIMENT_VERSION',
  DYNAMIC_V1: 'DYNAMIC_V1',
  DYNAMIC_V2: 'DYNAMIC_V2',
  LABOR_ALLOCATION_V1: 'LABOR_ALLOCATION_V1',
  LABOR_ALLOCATION_V2: 'LABOR_ALLOCATION_V2'
};

export const VLAB_ROOT_STATION = {
  na: 'US_ROOT',
  eu: 'AMZN_UK',
  fe: 'US_ROOT'
};

export const TIME_WINDOW_FORMAT = new RegExp(`^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] - (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$`);

export const DATE_TIME_FORMAT = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short',
  hourCycle: 'h23'
};

export const DATE_TIME_FORMAT_WITHOUT_TZ = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h23'
};

export const DATE_TIME_FORMAT_NUMERIC = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short',
  hourCycle: 'h23'
};

export const HOUR_MINUTE_ONLY_FORMAT = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h23'
};

export const HOUR_MINUTE_FORMAT = {
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
  hourCycle: 'h23'
};

export const processMapping = {
  Sort: [
    'Induct Dock W/S',
    'Inbound Dock W/S',
    'Inbound W/S',
    'Induct Line Loader',
    'Induct',
    'Auto Scan Induct Loader',
    'Pusher',
    'Auto Scan Pusher',
    'Diverter',
    'Auto Divert Straightener',
    'ADTA Container Building',
    'Container Building',
    'Pick to Buffer',
    'Non-Scan Stow',
    'Sort Problem Solve',
    'Stow',
    'Label',
    'Non-Con Manual Handling'
  ],

  'Pick Stage': [
    'Pick',
    'Pick & Stage - Pick',
    'Non-Scan Pick & Stage',
    'Pick Stage Problem Solve',
    'Pick Stage & Dispatch Problem Solve',
    'P&S Stage',
    'UTR Dispatch',
    'Yard Assist and Cart Handling'
  ],

  UTR: [
    'UTR OPS Supervisor / SA',
    'Stow Bag Replenishment',
    'Yard Marshall',
    'Learning Coordinator',
    'UTR Supervisor',
    'Problem Solvers - Sort',
    'Problem Solvers - Pick',
    'Empty Cart Returns'
  ],

  'Site Support': ['HR', 'Sustainability', 'Safety', 'Training / Safety School', 'Training Ambassador', '5S']
};

export const getTooltip = {
  VTO: 'Voluntary Time Off',
  VET: 'Voluntary Extra Time',
  Stow: 'Associate stowing packages from stow buffer to destination sort zone or oversize location.',
  'Target Rate': 'Latest ALPS Planned PPH / Target Rate',
  'Historical FCLM Rate': 'The average of best 3 out of last 4 week rates recorded in FCLM',
  'Productive Hrs': 'Total working hours of AA’s removing breaks and lunches',
  'Expected HC': 'Total associates that are expected to show up for work excluding for absence and attrition',
  'Expected HC + Day 1 New Hires': (
    <div>
      <div>
        {
          'Expected HC - Total associates that are expected to show up for work excluding absence, attrition and Day 1 new hires'
        }
      </div>
      <div>{'Day 1 New Hires - Total Day 1 associates that are scheduled in this shift'}</div>
    </div>
  ),
  'Rostered HC': 'Total associates that are scheduled to work in this shift including Day 1 new hires',
  'Day 1 New Hire HC': 'Total Day 1 associates that are scheduled in this shift',
  'Attendance %': 'The planned attendance % for rostered associates populated from ALPS',
  'Planned PPH': 'Latest ALPS Planned PPH/ Target Rate',
  'Inbound Dock W/S':
    'WS Associate located on the Inbound Dock who conveys packages or empty pallets from one location to another.  When unloading a pallet loaded truck they are the first station process path to move customer packages via pallet to the Induct Line Loader.  On a fluid loaded truck they are utilized generally on the I/B dock, moving pallets, Gaylords or ATS carts.',
  'Induct Line Loader':
    'Transfer of packages from either a staged pallet or fluid loaded truck to induct belt. Loaders activities include placing fluid load shipments direct from truck to belt or unloading palletized/ATS cart/gaylord shipments onto the induction belt.  This is the first sequential path to handle customer packages in a fluid load and second in a palletized/ats cart/gaylord load.',
  'Auto Scan Induct Loader': 'Induct Line Loader for the Auto Scan Label (ASL) machine, if installed.',
  Induct: 'Inductor scanning packages onto primary belts, smalls induction, and non-conveyable inducted packages. ',
  Pusher:
    'Pusher at end of Induct Belt.  Job function directly following Induct Scan. (sometimes called Primary Diverter)',
  'Auto Scan Pusher':
    'Diverter/Pusher at end of Induct Belt of an Auto Scan Label (ASL) machine, if installed. Job function directly following Induct Scan from ASL.',
  Diverter: 'Diverter.  Job function is diverting shipments down fingers.  (sometimes called Secondary Diverter)',
  'Pick to Buffer': 'Associate picking packages from finger and placing on the stow buffer racks',
  'Sort Problem Solve': 'Problem Solver dedicated to Container Sort core process.',
  'Auto Divert Straightener':
    'Job function is supporting singulation for (Auto Divert to Aisle) ADTA machine, if installed.',
  'Container Building': 'Associate stowing packages from stow buffer to destination sort zone or oversize location.',
  'Non-Scan Stow':
    'Associate performing stow function when no direct path scan is available, stowing non Conveyable packages and TETRIS Associates.',
  'Pick & Stage - Pick': 'Picking and staging routes, based on pick scans',
  'Non-Scan Pick & Stage':
    'Associate performing pick & stage function when no direct path scan is available (ie. dynamic sort). Includes additional labor support to the Pick & Stage operation such as team lift, reaching top tier racks or support for large routes. **Please note that the pick & stage direct paths changed in 2021 and vary based on region.**',
  'UTR Dispatch': 'Water Spider working in dispatch area, including Driver Assist.',
  'Pick Stage Problem Solve': 'Problem Solver dedicated to Pick Stage core process.',
  'UTR OPS Supervisor / SA': 'UTR Ops Supervisor/Shift Assistant in any UTR support role, including dispatch.',
  'Stow Bag Replenishment':
    'Any Associate replacing stow bags into empty sort zones.  This function replaces the bags removed during Pick & Stage.',
  'Learning Coordinator':
    'Learning coordinator hours used to support site (staffed Learning Coordinators); Day 1 Experience Assistants.',
  'Yard Marshall': 'Associates working as Traffic Controller and Yard Marshall (inbound or outbound dock)',
  Sustainability: 'Sustainability Ambassadors supporting sustainability-focused initiatives in station.',
  Safety:
    'Associates involved in safety committee meetings, safety audits, or safety incidents.  Social Distancing Champions, temperature checks and Project UV.',
  'Volume Split': 'Volume Split Override provided by Network Control Team',
  'Labor Board': 'Virtual Labor Board, associates are assigned to process paths as per ShiftPlan and JobRotation'
};

export const TEXTS = {
  H1: 'h500',
  H2: 'h300',
  H3: 'h200',
  H4: 'h100',
  T1: 'b400',
  T2: 'b300',
  T3: 'b200',
  T4: 'b100'
};

export const METRICS = {
  COUNT: 'count',
  TIME: 'time'
};

export const DYNAMIC_PLAN_RENAMED_ROLES = {
  Label: 'Induct',
  'Container Building': 'Stow',
  'Pick & Stage - Pick': 'Pick and Stage'
};

export const DYNAMIC_PLAN_SHOW_PLANNED_FLOW_FOR_PROCESSES = ['Container Building', 'ADTA Container Building', 'Label'];

export const FIRST_DYNAMIC_PLAN_ROLES_TO_SHOW = [
  'Induct Line Loader',
  'Pusher',
  'Label',
  'Diverter',
  'Pick to Buffer',
  'Container Building',
  'Pick & Stage - Pick'
];

export const VTO_VET_RECOMMENDATION_TABLE_TITLE =
  'VTO/VET Recommendations Assume No More Acceptance to Submitted Opportunities';

export const DYNAMIC_PLAN_CHANGE_MESSAGE =
  'This is an optional labor recommendation and currently under testing to help you manage flow through the shift.';

export const DYNAMIC_PLAN_ACCEPT_INFO =
  'Please accept the new plan and make the requested labor moves to maintain flow. If you see any issues, ' +
  'reject the plan and provide relevant reasons. Your inputs will help us in improving shift planning.';

export const DYNAMIC_PLAN_OVERRIDE_MESSAGE =
  'If there are changes to any inputs that do not reflect reality please make overrides and share rejection reasons.';
export const DEFAULT_OVERRIDE_REASON = 'Inaccurate value.';

export const DYNAMIC_PLAN_REJECT_REASON_CODES = [
  'Volume higher/lower than usual',
  'Rollover risk expected/inaccurate',
  'Overall headcount inaccurate',
  'Ops adjustment to manage flow/backlog/routes at risk',
  'Not utilizing all available labor',
  'VTO recommendation when plan suggesting rollover risk',
  'Other'
];

export const DYNAMIC_PLAN_DIFF = {
  PREVIOUS_HC: 'Previous HC',
  UPDATED_HC: 'Updated HC',
  DIFF_HC: 'Diff HC'
};

export const FEATURES = {
  IS_DYNAMIC_SHIFT_PLAN: 'isDynamicShiftPlanEnabled'
};

export const M5_LABOR_REASON_CODES = {
  LOW_INBOUND_VOLUME: 'Inbound volume low',
  HIGH_BUFFER_RACKS: 'Buffer racks levels are high',
  LOW_BUFFER_RACKS: 'Buffer racks levels are low',
  AVAILABLE_HEADCOUNT_INCREASED: 'Available headcount increased',
  AVAILABLE_HEADCOUNT_DECREASED: 'Available headcount decreased'
};

export const ERROR_CODES = {
  SHIFT_PLAN_ALREADY_CREATED: 'A shift plan has already been created. Please try again in 5 minutes.',
  SHIFT_PLAN_UPDATE_ERROR: 'Plan maybe cancelled by another user. Check History page for more details.'
};

export const AMAZON_SUFFIX = '@amazon.com';
export const TIMEOUT_STRING = 'timeout';

export const ALPS_PLANNED_ZERO_PPH_WARNING =
  'This process path will not be included in Shift plan since the rate was not set in ALPS Labor plan';

export const CO_USER_MIN_ALLOWED_VALUE = 0;
export const CO_USER_MAX_ALLOWED_VALUE = Number.MAX_SAFE_INTEGER;

export const DEFAULT_EXPERIMENT_MODE = 'EXPERIMENT_COMBINED_MECH_CONSTRAINT_SHIFT_LEVEL_BREAKS';

export const PROD = 'prod';
export const GAMMA = 'gamma';
export const INTEG = 'integ';

export const SHIFT_PLAN_SUGGESTED_INPUT = 'shiftPlanSuggestedInput';

export const SCC_SHIFTPLAN_MANAGER = ['Station Settings Manager'];

export const ROOT_STATIONS = {
  NA: ['US_ROOT', 'BR_ROOT', 'MX_ROOT', 'CA_ROOT'],
  EU: [
    'AE_ROOT',
    'AMZN_DE',
    'AMZN_ES',
    'AMZN_IT',
    'AMZN_FR',
    'AMZN_AT',
    'AMZN_UK',
    'AMZN_IE',
    'SA_ROOT',
    'EG_ROOT',
    'TR_ROOT',
    'BE_ROOT',
    'NL_ROOT'
  ],
  JP: ['JP_AMZL_ROOT', 'AU_ROOT', 'SG_ROOT'],
  IN: ['IN_ROOT']
};

export const SETTINGSV2_PERMISSIONS_REGION = ['na', 'eu'];

export const NODE_TYPE = {
  RSR: 'RSR',
  SSD: 'SSD',
  AMZL: 'AMZL'
};

export const SHIFT_ALLOCATION_ID_PREFIX = {
  PROCESS_CARD_ID_PREFIX: 'process-card-container',
  CLUSTER_CARD_ID_PREFIX: 'cluster-card-container',
  DRAGGABLE_ASSOCIATE: 'draggable-associate',
  EMPTY_ASSOCIATE_PREFIX: 'empty-associate'
};

export const PLACEMENT_ISSUE_REASON = {
  DISALLOWED_PATH: 'Disallowed path',
  UNTRAINED_PATH: 'Untrained path',
  TRACKING_TO_DIFFERENT_PATH : 'Tracking to different path -'
};

export const SHIFT_ALLOCATION_TABS = {
  INDUCT_STOW: 'Induct & Stow',
  PICK_STAGE: 'Pick & Stage'
};

export const CYCLES = {
  CYCLE_1: 'CYCLE_1'
};

export const SHIFT_ALLOCATION_ID_SUFFIX = {
  PROCESS_DETAIL_CARD: 'PROCESS_DETAIL_CARD',
  CLUSTER_DETAIL_CARD: 'CLUSTER_DETAIL_CARD',
  RECOMMENDED_SPOT_DROP: 'recommended-spot-drop',
  BENCH_DETAIL_CARD: 'BENCH_DETAIL_CARD'
};

export const SSD_OVERRIDE_REASON_CODES = {
  WEATHER: {
    label: 'Weather event',
    value: 'WEATHER'
  },
  LSE_SEV: {
    label: 'Tech LSE / Sev',
    value: 'LSE_SEV'
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER'
  },
  EXCESSIVE_ATTRITION: {
    label: 'Excessive Attrition',
    value: 'EXCESSIVE_ATTRITION'
  },
  PLANNED_EVENT: {
    label: 'planned Event',
    value: 'PLANNED_EVENT'
  },
  NO_REASON_GIVEN: {
    label: '-',
    value: 'NO_REASON_GIVEN'
  },
  DEMAND_LIGHTNESS: {
    label: 'Demand Lightness',
    value: 'DEMAND_LIGHTNESS'
  },
  OVERCHARGING: {
    label: 'Overcharging',
    value: 'OVERCHARGING'
  },
  SEV_ADDITIONAL_CAP_DOWN: {
    label: 'SEV / Additional Cap Down',
    value: 'SEV_ADDITIONAL_CAP_DOWN'
  },
  INJECTION_REDUCED_CANCELLED: {
    label: 'Injection Reduced / Cancelled',
    value: 'INJECTION_REDUCED_CANCELLED'
  },
  SITE_OVER_PERFORMING_PPA: {
    label: 'Site Over-performing (PPA)',
    value: 'SITE_OVER_PERFORMING_PPA'
  },
  SITE_UNDER_PERFORMING_PPA: {
    label: 'Site Under-performing (PPA)',
    value: 'SITE_UNDER_PERFORMING_PPA'
  },
  NEW_SITE: {
    label: 'New Site',
    value: 'NEW_SITE'
  },
  OI_REQUEST: {
    label: 'OI Request',
    value: 'OI_REQUEST'
  }
};
export const MANDATORY_SSD_ADMIN_SETTINGS_KEYS = [
  'singles_enabled',
  'ops_clock',
  'break_config',
  'shifts',
  'roles',
  'timeline',
  'timezone'
];
export const SSD_FIRST_SHIFT_HALF_INDICATOR = '1H';
export const SSD_SECOND_SHIFT_HALF_INDICATOR = '2H';
export const SSD_TIMELINE_KEY = {
  PLAN: 'plan',
  SHIFT: 'shift',
  SHIFT_HALF: 'shift_half',
  CET: 'cet',
  CPT: 'cpt',
  BREAK: 'break'
};
export const SSD_TIMELINE_TYPE = {
  START: 'start',
  END: 'end'
};
export const SSD_TIMELINE_LABEL = {
  PLAN_START: 'Plan Start',
  PLAN_END: 'Plan End',
  END: 'end'
};

export const SSD_TRAILER_VOLUME_SOURCE_TYPE = {
  SNOP: 'SNOP',
  SCC: 'SCC'
};
export const SSD_TRAILER_STATUS = {
  PENDING_DEPART: {
    value: 'PENDING_DEPART',
    label: 'Pending Depart'
  },
  ARRIVED: {
    value: 'ARRIVED',
    label: 'Arrived'
  },
  IN_TRANSIT: {
    value: 'IN_TRANSIT',
    label: 'In Transit'
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'Cancelled'
  }
};
export const SSD_NULL_VRID = 'nullVRID';

export const ADMIN_SETTINGS_DAILY_SHIFTS = 'daily_shifts';
export const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const MSR_TOOL_TIP =
  'Max Sustainable Rate (MSR) = This represents the max rate an tenured associate can perform sustainably. ' +
  "Conductor will plan for an 'expected rate' that is at the MSR or lower because of limitations such as new hire dilution or not having enough available volume. " +
  'See expected rate range for an estimate of range of where the expected rate will land.';

export const SSD_VOLUME_PROCESS_TYPE = {
  PICK_2_REBIN: {
    label: 'Pick2Rebin (units)',
    value: 'pick2rebin'
  },
  VNA: {
    label: 'VNA (units)',
    value: 'vna'
  },
  SINGLES: {
    label: 'Singles',
    value: 'singles'
  }
};
