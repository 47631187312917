import React, { useCallback, useMemo } from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Divider from '@amzn/meridian/divider';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { EmployeeIcon } from './EmployeeIcon';
import { SHIFT_ALLOCATION_ID_PREFIX, SHIFT_ALLOCATION_ID_SUFFIX } from '../../utils/constants';
import { EmptyEmployeeIcon } from './EmptyEmployeeIcon';
import { PROCESS_PATHS, PROCESS_TYPE } from '../data';
import { getUnassignedClusterChunks, getClusterCardHeaderColor, aisleAssignmentHeaderFormatter } from './utils';
import UpsertAislesModel from './UpsertAislesModel';
import Box from '@amzn/meridian/box';

const MAX_HEIGHT_FOR_PLACEHOLDER = 10;

export const ClusterDetailCard = ({ boardEntry, shiftAllocationModel, shiftAllocationDispatch, setIsBoardUpdated }) => {
  const { associateInfo, clusterDefinitions } = shiftAllocationModel;
  const isADTAStow = useMemo(() => {
    return boardEntry.type === PROCESS_TYPE.ADTA_CLUSTER;
  }, [boardEntry]);

  const getEmptyAssignmentList = (boardEntry, processPathId) => {
    const emptyAssignmentList = [];
    const matchingClusterDefinitions = clusterDefinitions.filter(
      (clusterDefinition) => `cluster_${clusterDefinition.identifier}` === boardEntry.id
    );

    matchingClusterDefinitions.forEach((clusterDefinition) => {
      const unassignedClusterChunks = getUnassignedClusterChunks(boardEntry, processPathId, clusterDefinition);
      unassignedClusterChunks.forEach((chunk, index) => {
        const droppableId = `${boardEntry.id}-${processPathId}-${chunk.minUnassignedAisleNo}-${chunk.maxUnassignedAisleNo}-${SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP}-${SHIFT_ALLOCATION_ID_SUFFIX.CLUSTER_DETAIL_CARD}`;
        emptyAssignmentList.push(
          <Droppable
            droppableId={droppableId}
            type={'PROCESS_PATH'}
            key={`${boardEntry.id}-${chunk.minUnassignedAisleNo}-${chunk.maxUnassignedAisleNo}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                id={`${SHIFT_ALLOCATION_ID_PREFIX.EMPTY_ASSOCIATE_PREFIX}-${droppableId}`}>
                <EmptyEmployeeIcon
                  header={`${chunk.minUnassignedAisleNo}-${chunk.maxUnassignedAisleNo}`}
                  boardEntry={boardEntry.id}
                  containerType={SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}
                  minUnassignedAisleNo={chunk.minUnassignedAisleNo}
                  maxUnassignedAisleNo={chunk.maxUnassignedAisleNo}
                  droppableId={droppableId}
                  placeHolder={provided.placeholder}
                />
              </div>
            )}
          </Droppable>
        );
      });
    });
    return emptyAssignmentList;
  };

  const renderStowAssociates = useCallback(() => {
    const renderList = boardEntry.assignments
      .filter((assignment) => {
        if (isADTAStow) {
          return assignment.allocationAttrs.process === PROCESS_PATHS.ADTA_STOW;
        } else {
          return assignment.allocationAttrs.process === PROCESS_PATHS.STOW;
        }
      })
      .map((assignment, index) => {
        const header = aisleAssignmentHeaderFormatter(assignment);
        return (
          <Draggable
            // adding a key is important!
            key={assignment.resourceId}
            draggableId={assignment.resourceId}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                id={`${SHIFT_ALLOCATION_ID_PREFIX.DRAGGABLE_ASSOCIATE}-${assignment.resourceId}`}
              >
                <EmployeeIcon
                  boardEntry={boardEntry}
                  associate={associateInfo[assignment.resourceId]}
                  assignedProcess={assignment.allocationAttrs.process}
                  header={header}
                />
              </div>
            )}
          </Draggable>
        );
      });

    renderList.push(...getEmptyAssignmentList(boardEntry, isADTAStow ? PROCESS_PATHS.ADTA_STOW : PROCESS_PATHS.STOW));
    return renderList;
  }, [boardEntry]);

  const renderPickToBufferAssociates = () => {
    const renderList = boardEntry.assignments
      .filter((assignment) => assignment.allocationAttrs.process === PROCESS_PATHS.PICK_TO_BUFFER)
      .map((assignment, index) => {
        const header = aisleAssignmentHeaderFormatter(assignment);
        return (
          <Draggable
            // adding a key is important!
            key={assignment.resourceId}
            draggableId={`${assignment.resourceId}`}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                id={`${SHIFT_ALLOCATION_ID_PREFIX.DRAGGABLE_ASSOCIATE}-${assignment.resourceId}`}
              >
                <EmployeeIcon
                  boardEntry={boardEntry}
                  associate={associateInfo[assignment.resourceId]}
                  assignedProcess={assignment.allocationAttrs.process}
                  header={header}
                />
              </div>
            )}
          </Draggable>
        );
      });

    renderList.push(...getEmptyAssignmentList(boardEntry, PROCESS_PATHS.PICK_TO_BUFFER));
    return renderList;
  };

  const renderPickRow = () => {
    const droppableId = `${boardEntry.id}-${boardEntry.processPathEntries[0].process}-${SHIFT_ALLOCATION_ID_SUFFIX.CLUSTER_DETAIL_CARD}`;
    return (
      <div
        className="no-margin"
        style={{
          display: 'flex',
          width: '100%',
          minHeight: '88px',
          alignItems: 'center'
        }}
      >
        <div style={{ writingMode: 'tb-rl', transform: 'rotate(180deg)', display: 'flex', alignItems: 'center' }}>
          <Text>{'P to B'}</Text>
        </div>
        <Droppable droppableId={droppableId} type={'PROCESS_PATH'}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{ width: '100%', borderLeft: '1px solid #D9D9D9' }}
              {...provided.droppableProps}
            >
              <Row
                alignmentHorizontal={'start'}
                spacingInset={'small'}
                spacing="xsmall"
                wrap={'down'}
                widths={['grid-4']}
                id={`${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${droppableId}`}
              >
                {renderPickToBufferAssociates()}
              </Row>
              <div id={'placeholder-for-cluster'} style={{ maxHeight: MAX_HEIGHT_FOR_PLACEHOLDER }}>
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  const renderStowRow = () => {
    let droppableId;
    if (isADTAStow) {
      droppableId = `${boardEntry.id}-${boardEntry.processPathEntries[0].process}-${SHIFT_ALLOCATION_ID_SUFFIX.CLUSTER_DETAIL_CARD}`;
    } else {
      droppableId = `${boardEntry.id}-${boardEntry.processPathEntries[1].process}-${SHIFT_ALLOCATION_ID_SUFFIX.CLUSTER_DETAIL_CARD}`;
    }
    return (
      <div
        className="no-margin"
        style={{
          display: 'flex',
          width: '100%',
          minHeight: '88px',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            writingMode: 'tb-rl',
            transform: 'rotate(180deg)',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Text id="verticalText">{isADTAStow ? 'ADTA Stow' : 'Stow'}</Text>
        </div>
        <Droppable droppableId={droppableId} type={'PROCESS_PATH'}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{ width: '100%', borderLeft: '1px solid #D9D9D9' }}
              {...provided.droppableProps}
            >
              <Row
                alignmentHorizontal={'start'}
                spacingInset={'small'}
                spacing="xsmall"
                wrap={'down'}
                widths={['grid-4']}
                id={`${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${droppableId}`}
              >
                {renderStowAssociates()}
              </Row>
              <div id={'placeholder-for-cluster'} style={{ maxHeight: MAX_HEIGHT_FOR_PLACEHOLDER }}>
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  return (
    <Row spacingInset={'none'}>
      <Column spacingInset={'none'} spacing="none" type={'outline'} minWidth={120}>
        <div
          style={{
            backgroundColor: getClusterCardHeaderColor(boardEntry, associateInfo, clusterDefinitions),
            minHeight: '20px',
            alignmentHorizontal: 'center'
          }}>
          <Row width="100%" alignmentHorizontal="center" alignmentVertical="center" spacing="100" spacingInset="100">
            <Box spacingInset="100" minHeight={5}>
              <Text alignment="center" type={'h100'} className={'clusterDetailCardTitle'}>
                {boardEntry.title}
              </Text>
            </Box>
            <Box spacingInset="100" minHeight={5}>
              <UpsertAislesModel
                processInfoEntry={boardEntry}
                shiftAllocationModel={shiftAllocationModel}
                shiftAllocationDispatch={shiftAllocationDispatch}
                setIsBoardUpdated={setIsBoardUpdated}
              />
            </Box>
          </Row>
        </div>
        <div id={`${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${boardEntry.id}`}>
          {isADTAStow ? null : renderPickRow()}
          <Divider />
          {renderStowRow()}
        </div>
      </Column>
    </Row>
  );
};
