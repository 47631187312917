import React, { useState } from 'react';
import InputGroup from '@amzn/meridian/input-group';
import Input from '@amzn/meridian/input';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import InputMask from '@amzn/meridian/input/mask';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import closeCircleTokens from '@amzn/meridian-tokens/base/icon/close-circle';
import alertFilledTokens from '@amzn/meridian-tokens/base/icon/alert-knockout';
import plusKnockoutTokens from '@amzn/meridian-tokens/base/icon/plus-knockout';
import Column from '@amzn/meridian/column';
import { CLUSTER_TYPES } from '../data';
import { upsertClustersAndAisles } from '../../utils/ShiftAllocationModelHelper';

const prefixOptions = Object.entries(CLUSTER_TYPES).map(([value, { label }]) => ({
  value,
  label
}));

const clusterOptions = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ').map((letter) => ({
  value: letter,
  label: letter
}));

const aisleNumberMask = new InputMask('###');

const defaultInputGroup = {
  prefix: prefixOptions[0].value,
  cluster: clusterOptions[0].value,
  minAisle: null,
  maxAisle: null
};

const UpsertClustersModel = ({ adminSettings, shiftAllocationModel, shiftAllocationDispatch, setIsBoardUpdated }) => {
  const [prefixValue, setPrefixValue] = useState(prefixOptions[0].value);
  const [clusterValue, setClusterValue] = useState(clusterOptions[0].value);
  const [minAisle, setMinAisle] = useState(null);
  const [maxAisle, setMaxAisle] = useState(null);
  const [error, setError] = useState(false);
  const [inputGroups, setInputGroups] = useState([]);
  const [open, setOpen] = useState(false);

  const onClickButton = () => {
    setError(false);
    setOpen(true);
    setInputGroups([{ ...defaultInputGroup }]);
  };

  const onClose = () => {
    setError(false);
    setOpen(false);
    setInputGroups([]);
  };

  const onClickConfirmButton = () => {
    const isInvalid = inputGroups.some((group) => {
      const minAisle = parseInt(group.minAisle);
      const maxAisle = parseInt(group.maxAisle);

      return !group.minAisle || !group.maxAisle || minAisle >= maxAisle;
    });

    if (isInvalid) {
      setError(true);
      setOpen(true);
    } else {
      upsertClustersAndAisles(inputGroups, adminSettings, shiftAllocationModel, shiftAllocationDispatch);
      setOpen(false);
      setInputGroups([]);
      setIsBoardUpdated(true);
    }
  };

  const handleAddClick = () => {
    setInputGroups([...inputGroups, { ...defaultInputGroup }]);
    resetForm();
  };

  const resetForm = () => {
    setPrefixValue(prefixOptions[0].value);
    setClusterValue(clusterOptions[0].value);
    setMinAisle(null);
    setMaxAisle(null);
  };

  const handleInputChange = (index, field, value) => {
    setInputGroups((prevInputGroups) => {
      const updatedInputGroups = [...prevInputGroups];
      updatedInputGroups[index][field] = value.trim();
      return updatedInputGroups;
    });
  };

  const onRemoveInput = (index) => {
    setInputGroups(inputGroups.filter((_, i) => i !== index));
  };

  return (
    <React.Fragment>
      <Button
        type="tertiary"
        size="small"
        onClick={onClickButton}
        disabled={!shiftAllocationModel['boardAllocationKey']}>
        Clusters
      </Button>
      <Modal
        title="Cluster Manager"
        open={open}
        onClose={onClose}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby="modal-description"
        bodySpacingInset="400">
        <div>
          <div style={{ marginBottom: '2rem' }}>
            <Row>
              <Text alignment="center">{'Add clusters and aisles to the labor board'}</Text>
            </Row>
          </div>

          {/* Flex container for input groups */}
          <div>
            {inputGroups.map((group, index) => (
              <Column key={index}>
                <Row style={{ width: '100%', height: 1, backgroundColor: 'rgba(0,0,0,0.1)' }}></Row>
                <Row>
                  <InputGroup size="medium" suffix={'Aisles'} style={{ flex: 100 }}>
                    <Select
                      width={100}
                      value={group.prefix}
                      onChange={(value) => handleInputChange(index, 'prefix', value)}
                      size="medium">
                      {prefixOptions.map((option) => (
                        <SelectOption label={option.label} value={option.value} key={option.value} size="medium" />
                      ))}
                    </Select>
                    <Select
                      width={75}
                      value={group.cluster}
                      onChange={(value) => handleInputChange(index, 'cluster', value)}
                      size="medium">
                      {clusterOptions.map((option) => (
                        <SelectOption key={option.value} value={option.value} label={option.label} size="medium" />
                      ))}
                    </Select>
                    <Input
                      width={75}
                      value={group.minAisle !== null ? group.minAisle.toString() : ''}
                      onChange={(value) => handleInputChange(index, 'minAisle', value)}
                      type="number"
                      mask={aisleNumberMask}
                    />
                    <Input
                      width={150}
                      value={group.maxAisle !== null ? group.maxAisle.toString() : ''}
                      onChange={(value) => handleInputChange(index, 'maxAisle', value)}
                      type="number"
                      mask={aisleNumberMask}
                    />
                  </InputGroup>
                  <Button type="icon" size="medium" onClick={() => onRemoveInput(index)}>
                    <Icon tokens={closeCircleTokens} color="error">
                      Error
                    </Icon>
                  </Button>
                </Row>
              </Column>
            ))}
          </div>
        </div>

        <ModalFooter>
          <Row alignmentHorizontal="justify">
            <Row alignmentHorizontal="start">
              <Button onClick={handleAddClick} type="tertiary" size="medium">
                <Icon tokens={plusKnockoutTokens} /> Add More
              </Button>
            </Row>

            {error ? (
              <Row alignmentHorizontal="center">
                {' '}
                <Icon tokens={alertFilledTokens} color="error" />{' '}
                <Text alignment="center" color="error">
                  {'Invalid aisles'}
                </Text>{' '}
              </Row>
            ) : (
              <></>
            )}

            <Row alignmentHorizontal="end" widths="fit">
              <Button type="secondary" size="medium" onClick={onClose}>
                Cancel
              </Button>
              <Button type="primary" size="medium" onClick={onClickConfirmButton}>
                Confirm
              </Button>
            </Row>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default UpsertClustersModel;
