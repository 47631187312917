import apis from './apis';

export const getAdminSettings = (stationCode) => {
  const request = {
    operationType: 'get',
    queryParams: 'stationCode=' + stationCode
  };
  return apis
    .GET_ADMIN_SETTINGS(request)
    .then((getS3DownloadUrlResponse) => {
      if (getS3DownloadUrlResponse) {
        return fetch(getS3DownloadUrlResponse.url).then((s3DownloadResponse) => {
          if (s3DownloadResponse.status === 200) {
            return s3DownloadResponse.json().then((adminSettings) => {
              return adminSettings;
            });
          } else {
            console.log('Admin Settings file does not exist.');
          }
        });
      } else {
        console.log('Network Error: Failed to fetch admin settings file');
      }
    })
    .catch((error) => {
      console.log(`Failed to fetch admin settings file. Error: ${error}`);
    });
};

