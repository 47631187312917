import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Tooltip from '@amzn/meridian/tooltip';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import { isValidDragAndDrop } from '../../utils/dragAndDropHelper';
import Column from '@amzn/meridian/column';
import { MOVE_ASSOCIATE } from '../action/ShiftAllocationAction';
import { ShiftAllocationContext } from '../context/ShiftAllocationContext';
import { fixMarginAndWidth } from '../../utils/shiftAllocation';
import {
  areUnAssignedClustersChunksPresent,
  getAislesOptionsForBoardEntry,
  getHeadcountForProcessPaths,
  getPlanChangeViolations
} from './utils';
import ViolationsToggle from './ViolationsToggle';
import { ALLOCATION_TYPE, EMPTY_PROCESS, PROCESS_PATHS } from '../data';

const OnDragEndModal = ({ open, setOpen, result, provided, processInfo, associateInfo, setIsBoardUpdated }) => {
  const onClose = () => setOpen(false);
  const [subProcessPathChosen, setSubProcessPathChosen] = useState();
  const [minAisle, setMinAisle] = useState();
  const [maxAisle, setMaxAisle] = useState();
  const [violationsOverride, setViolationsOverride] = useState(false);
  const { shiftAllocationModel, shiftAllocationDispatch } = useContext(ShiftAllocationContext);

  const toBoardEntryId = useMemo(() => {
    return result && result.destination.droppableId.split('-')[0];
  }, [result]);

  const toProcessPathId = useMemo(() => {
    return result && result.destination.droppableId.split('-')[1];
  }, [result]);

  const toBoardEntry = useMemo(() => {
    return processInfo.find((entry) => entry.id === toBoardEntryId);
  }, [toBoardEntryId, processInfo]);

  const fromBoardEntryId = useMemo(() => {
    return result && result.source.droppableId.split('-')[0];
  }, [result]);

  const violations = useMemo(() => {
    if (toBoardEntryId) {
      const violations = getPlanChangeViolations(result, processInfo, associateInfo, subProcessPathChosen);
      return violations;
    } else {
      return [];
    }
  }, [toBoardEntryId, subProcessPathChosen, processInfo, result]);

  useEffect(() => {
    checkViolationsOverride(violations.map((v) => !v));
  }, [violations]);

  const checkViolationsOverride = useCallback((values) => {
    setViolationsOverride(values.every((v) => v));
  }, []);

  const onCancelButtonClick = () => setOpen(false);

  const getInitialProcessPathChosen = useCallback(() => {
    // check if process path has subprocesses
    const boardEntry = processInfo.find((entry) => entry.id === toBoardEntryId);
    const headCounts = getHeadcountForProcessPaths(boardEntry);
    for (const headCount of headCounts) {
      if (headCount.actualHeadcount < headCount.required) {
        setSubProcessPathChosen(headCount.process);
        return;
      }
    }
    setSubProcessPathChosen(boardEntry.processPathEntries[0] && boardEntry.processPathEntries[0].process);
  }, [processInfo, toBoardEntryId]);

  const getInitialAisleSpan = useCallback(() => {
    const unassignedClusterChunks = areUnAssignedClustersChunksPresent(
      toBoardEntry,
      toProcessPathId,
      shiftAllocationModel.clusterDefinitions
    );

    if (unassignedClusterChunks.length !== 0) {
      setMinAisle(String(unassignedClusterChunks.minUnassignedAisleNo));
      setMaxAisle(String(unassignedClusterChunks.maxUnassignedAisleNo));
    } else {
      const minAisleNo = 3;
      const maxAisleNo = 20;
      setMinAisle(String(minAisleNo));
      setMaxAisle(String(maxAisleNo));
    }
  }, [processInfo, toProcessPathId, toBoardEntry, shiftAllocationModel]);

  const getInitialValue = useCallback(() => {
    if (isValidDragAndDrop(result, provided)) {
      if (toBoardEntry.allocationType === ALLOCATION_TYPE.AISLES) {
        getInitialAisleSpan();
      } else {
        getInitialProcessPathChosen();
      }
    }
  }, [result, toBoardEntry, provided]);

  useEffect(() => {
    if (open) {
      getInitialValue();
    }
  }, [open]);

  const onConfirmButtonClick = useCallback(() => {
    if (isValidDragAndDrop(result, provided)) {
      if (toBoardEntry.allocationType === ALLOCATION_TYPE.AISLES) {
        shiftAllocationDispatch({
          type: MOVE_ASSOCIATE,
          data: {
            associateAlias: result.draggableId,
            to: {
              boardEntry: toBoardEntryId,
              processPath: toProcessPathId,
              minAisle: Number(minAisle),
              maxAisle: Number(maxAisle)
            },
            from: {
              boardEntry: fromBoardEntryId
            }
          }
        });
      } else {
        shiftAllocationDispatch({
          type: MOVE_ASSOCIATE,
          data: {
            associateAlias: result.draggableId,
            to: {
              boardEntry: toBoardEntryId,
              processPath: subProcessPathChosen
            },
            from: {
              boardEntry: fromBoardEntryId
            }
          }
        });
      }
      setOpen(false);
      setViolationsOverride(false);
      setIsBoardUpdated(true);
      setTimeout(fixMarginAndWidth, 300);
    }
  }, [
    processInfo,
    toBoardEntryId,
    toProcessPathId,
    fromBoardEntryId,
    result,
    provided,
    minAisle,
    maxAisle,
    shiftAllocationDispatch,
    subProcessPathChosen
  ]);

  const renderHeaderString = useCallback(() => {
    if (isValidDragAndDrop(result, provided)) {
      if (subProcessPathChosen === null || subProcessPathChosen === EMPTY_PROCESS) {
        return 'Confirm overrides';
      } else if (
        result.destination.droppableId.includes(PROCESS_PATHS.PICK_TO_BUFFER) ||
        result.destination.droppableId.includes(PROCESS_PATHS.STOW)
      ) {
        return 'Aisle selection';
      } else {
        return 'Process path selection';
      }
    }
  }, [result, provided, subProcessPathChosen]);

  const renderTitle = useCallback(() => {
    if (isValidDragAndDrop(result, provided)) {
      if (subProcessPathChosen === null || subProcessPathChosen === EMPTY_PROCESS) {
        return null;
      } else if (
        result.destination.droppableId.includes(PROCESS_PATHS.PICK_TO_BUFFER) ||
        result.destination.droppableId.includes(PROCESS_PATHS.STOW)
      ) {
        return <Text id="modal-description">{'Select aisle span'}</Text>;
      } else {
        return <Text id="modal-description">{'Select process path'}</Text>;
      }
    }
  }, [result, provided, subProcessPathChosen]);

  const renderProcessPathOptions = useCallback(() => {
    return (
      <Select value={subProcessPathChosen} onChange={setSubProcessPathChosen} width={300}>
        {toBoardEntry.processPathEntries.map((processPath, index) => {
          return <SelectOption key={index} value={`${processPath.process}`} label={`${processPath.process}`} />;
        })}
      </Select>
    );
  }, [subProcessPathChosen, toBoardEntry]);

  const renderAisleSpanOptions = useCallback(() => {
    const aisleRangeList = getAislesOptionsForBoardEntry(toBoardEntry, shiftAllocationModel.clusterDefinitions);
    return (
      <Row>
        <Select value={minAisle} onChange={setMinAisle} width={300}>
          {aisleRangeList.map((aisleNo, index) => {
            return <SelectOption key={index} value={`${aisleNo}`} label={`${aisleNo}`} />;
          })}
          {}
        </Select>
        <Select value={maxAisle} onChange={setMaxAisle} width={300}>
          {aisleRangeList.map((aisleNo, index) => {
            return <SelectOption key={index} value={`${aisleNo}`} label={`${aisleNo}`} />;
          })}
        </Select>
      </Row>
    );
  }, [processInfo, toBoardEntry, minAisle, maxAisle, shiftAllocationModel]);

  const renderSelectionOptions = useCallback(() => {
    if (isValidDragAndDrop(result, provided)) {
      if (subProcessPathChosen === null || subProcessPathChosen === EMPTY_PROCESS || !toBoardEntry) {
        return null;
      }
      if (toBoardEntry.allocationType === ALLOCATION_TYPE.AISLES) {
        return renderAisleSpanOptions();
      } else {
        return renderProcessPathOptions();
      }
    }
  }, [result, provided, subProcessPathChosen, toBoardEntry, renderAisleSpanOptions, renderProcessPathOptions]);

  const renderViolationsToggle = useCallback(() => {
    return <ViolationsToggle violations={violations} onChange={checkViolationsOverride} />;
  }, [violations]);

  const renderModalView = useCallback(() => {
    return (
      <Column>
        <Row>{renderTitle()}</Row>
        <Row>{renderSelectionOptions()}</Row>
        <Row>{renderViolationsToggle()}</Row>
      </Column>
    );
  }, [renderTitle, renderSelectionOptions, renderViolationsToggle]);

  const renderConfirmButton = useCallback(() => {
    return (
      <Button
        aria-describedby="confirmButtonTooltip"
        type="primary"
        size="small"
        disabled={!violationsOverride}
        onClick={onConfirmButtonClick}
      >
        Confirm
      </Button>
    );
  }, [violationsOverride, onConfirmButtonClick]);

  return (
    <React.Fragment>
      <Modal
        title={renderHeaderString()}
        open={open}
        onClose={onClose}
        scrollContainer="viewport"
        closeLabel="Close"
        aria-describedby="modal-description"
      >
        {renderModalView()}
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={onCancelButtonClick}>
              Cancel
            </Button>
            {!violationsOverride ? (
              <Tooltip position="end" title="Confirm you are overriding violations" id="confirmButtonTooltip">
                {renderConfirmButton()}
              </Tooltip>
            ) : (
              renderConfirmButton()
            )}
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default OnDragEndModal;
