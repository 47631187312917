import React, { useCallback, useRef, useState } from 'react';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Button from '@amzn/meridian/button';
import Divider from '@amzn/meridian/divider';
import Text from '@amzn/meridian/text';
import chevronDownSmallTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import Icon from '@amzn/meridian/icon';
import { PROCESS_PATHS, PROCESS_TYPE } from '../data';
import { SET_MODEL } from '../action/ShiftAllocationAction';

const UpsertAislesModel = ({ processInfoEntry, shiftAllocationModel, shiftAllocationDispatch, setIsBoardUpdated }) => {
  const [currentProcessInfoEntry, setCurrentProcessInfoEntry] = useState(null);
  const [boardType, setBoardType] = useState(null);
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);

  const onClickButton = () => {
    setOpen(true);
    setCurrentProcessInfoEntry(processInfoEntry);
    setBoardType(processInfoEntry['type']);
  };

  const onClose = () => {
    setOpen(false);
    setCurrentProcessInfoEntry(null);
    setBoardType(null);
  };

  const editAllProcessAllocations = () => {
    // yet to implement
  };

  const removeAllProcessAllocations = useCallback(
    (selectedProcess) => {
      if (currentProcessInfoEntry && currentProcessInfoEntry.assignments) {
        const updatedAssignments = currentProcessInfoEntry.assignments.filter(
          (assignment) => assignment.allocationAttrs && assignment.allocationAttrs.process !== selectedProcess
        );

        if (currentProcessInfoEntry.assignments.length !== updatedAssignments.length) {
          const updatedProcessInfoEntry = { ...currentProcessInfoEntry, assignments: updatedAssignments };

          setCurrentProcessInfoEntry(updatedProcessInfoEntry);

          const updatedProcessInfoEntries = [
            ...shiftAllocationModel['processInfo'].filter((entry) => entry.id !== updatedProcessInfoEntry.id),
            updatedProcessInfoEntry
          ];

          shiftAllocationDispatch({
            type: SET_MODEL,
            payload: {
              ...shiftAllocationModel,
              processInfo: updatedProcessInfoEntries
            }
          });

          setIsBoardUpdated(true);
        }
      }
    },
    [currentProcessInfoEntry, shiftAllocationDispatch]
  );

  return (
    <React.Fragment>
      <Button
        ref={buttonRef}
        onClick={onClickButton}
        aria-haspopup="menu"
        aria-expanded="false"
        type="icon"
        size="small">
        <Icon tokens={chevronDownSmallTokens} className="icon-white" />
      </Button>
      {boardType === PROCESS_TYPE.SORT_CLUSTER ? (
        <Menu anchorNode={buttonRef.current} id="upsert-aisles-menu" open={open} position="bottom" onClose={onClose}>
          <MenuItem disabled>
            <Text type="h100" alignment="justify" color="secondary">
              P2B
            </Text>
          </MenuItem>
          <MenuItem disabled onClick={() => editAllProcessAllocations(PROCESS_PATHS.PICK_TO_BUFFER)}>Edit all P2B</MenuItem>
          <MenuItem onClick={() => removeAllProcessAllocations(PROCESS_PATHS.PICK_TO_BUFFER)}>Remove all P2B</MenuItem>
          <MenuItem disabled>
            <Divider size="medium" />
          </MenuItem>
          <MenuItem disabled>
            <Text type="h100" alignment="justify" color="secondary">
              Stow
            </Text>
          </MenuItem>
          <MenuItem disabled onClick={() => editAllProcessAllocations(PROCESS_PATHS.STOW)}>Edit all Stow</MenuItem>
          <MenuItem onClick={() => removeAllProcessAllocations(PROCESS_PATHS.STOW)}>Remove all Stow</MenuItem>
        </Menu>
      ) : (
        <Menu anchorNode={buttonRef.current} id="upsert-aisles-menu" open={open} position="bottom" onClose={onClose}>
          <MenuItem disabled>
            <Text type="h100" alignment="justify" color="secondary">
              ADTA Stow
            </Text>
          </MenuItem>
          <MenuItem disabled onClick={() => editAllProcessAllocations(PROCESS_PATHS.ADTA_STOW)}>Edit all ADTA Stow</MenuItem>
          <MenuItem onClick={() => removeAllProcessAllocations(PROCESS_PATHS.ADTA_STOW)}>Remove all ADTA Stow</MenuItem>
        </Menu>
      )}
    </React.Fragment>
  );
};

export default UpsertAislesModel;
