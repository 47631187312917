import { SET_MODEL } from '../shiftAllocation/action/ShiftAllocationAction';
import { ALLOCATION_TYPE, CLUSTER_TYPES } from '../shiftAllocation/data';

const createCluster = (clusterName, clusterType, clusterProcessPathEntry) => {
  return {
    allocationType: ALLOCATION_TYPE.AISLES,
    id: `cluster_${clusterName}`,
    processPathEntries: clusterProcessPathEntry,
    title: `${clusterName}`,
    type: clusterType,
    assignments: []
  };
};

const createClusterDefinitions = (identifier, isADTA, minAisle, maxAisle, cycle, type) => {
  const sortAisleReservations = [];

  for (let i = parseInt(minAisle); i <= parseInt(maxAisle); i++) {
    const aisleIdentifier = `${identifier}-${i}`;
    sortAisleReservations.push({
      identifier: aisleIdentifier,
      isReservedFor: cycle,
      status: 'RES',
      type: 'AISLE'
    });
  }

  return {
    identifier,
    isADTA,
    sortAisleReservations,
    type
  };
};

const createClusterBoardEntry = (clusterName, clusterType, clusterProcessPathEntry) => {
  return {
    allocationType: ALLOCATION_TYPE.AISLES,
    id: `cluster_${clusterName}`,
    processPathEntries: clusterProcessPathEntry,
    title: `${clusterName}`,
    type: clusterType
  };
};

export const upsertClustersAndAisles = (inputGroups, adminSettings, shiftAllocationModel, shiftAllocationDispatch) => {
  const newClusters = [];
  const newClusterNames = [];
  const newClusterDefinitions = [];
  const newBoardEntries = [];

  const boardConfig = adminSettings['AMZL']['boardConfig'];
  const processInfo = shiftAllocationModel['processInfo'];
  const boardEntries = shiftAllocationModel['boardEntries'];
  const clusterDefinitions = shiftAllocationModel['clusterDefinitions'];

  const boardAllocationKeys = shiftAllocationModel['boardAllocationKey'].split('.');
  const cycle = boardAllocationKeys[0];

  const existingClusters = boardEntries.filter((config) => config.allocationType === ALLOCATION_TYPE.AISLES);

  const existingClusterNames = existingClusters.reduce((acc, curr) => {
    const { type, title } = curr;
    if (!acc[type]) acc[type] = [];
    if (!acc[type].includes(title)) acc[type].push(title);
    return acc;
  }, {});

  const processBoardConfigs = boardConfig.filter((config) => config.allocationType === ALLOCATION_TYPE.AISLES);

  if (processBoardConfigs && processBoardConfigs.length > 0) {
    processBoardConfigs.forEach((config) => {
      if (config.processPathEntries && config.processPathEntries.length > 0) {
        config.processPathEntries = config.processPathEntries.map((entry) => ({
          ...entry,
          minResources: 1
        }));
      }
    });

    for (const inputGroup of inputGroups) {
      const clusterPrefix = inputGroup['prefix'];
      const clusterName = inputGroup['cluster'];
      const minAisle = inputGroup['minAisle'];
      const maxAisle = inputGroup['maxAisle'];

      if (clusterPrefix && clusterName && minAisle && maxAisle) {
        const clusterType = CLUSTER_TYPES[clusterPrefix]['type'];
        const clusterConfig = processBoardConfigs.find((config) => config.type === clusterType);
        const clusterProcessPathEntry =
          clusterConfig && clusterConfig.processPathEntries ? clusterConfig.processPathEntries : [];
        // Adding new clusters with aisles
        if (!existingClusterNames[clusterType] || !existingClusterNames[clusterType].includes(clusterName)) {
          if (!newClusterNames.includes(clusterName)) {
            const newBoardEntry = createClusterBoardEntry(clusterName, clusterType, clusterProcessPathEntry);
            newBoardEntries.push(newBoardEntry);
            const newCluster = createCluster(clusterName, clusterType, clusterProcessPathEntry);
            newClusters.push(newCluster);
            newClusterNames.push(clusterName);
          }

          const newClusterDefinition = createClusterDefinitions(
            clusterName,
            CLUSTER_TYPES[clusterPrefix]['isADTA'],
            minAisle,
            maxAisle,
            cycle,
            'CLUSTER'
          );
          newClusterDefinitions.push(newClusterDefinition);
        } else {
          // Updating existing clusters with new aisles
          const newClusterDefinition = createClusterDefinitions(
            clusterName,
            CLUSTER_TYPES[clusterPrefix]['isADTA'],
            minAisle,
            maxAisle,
            cycle,
            'CLUSTER'
          );
          newClusterDefinitions.push(newClusterDefinition);
        }
      }
    }

    const updatedProcessInfo = [...processInfo, ...newClusters];

    const updatedBoardEntries = [...boardEntries, ...newBoardEntries];

    const updatedClusterDefinitions = [...clusterDefinitions, ...newClusterDefinitions];

    shiftAllocationDispatch({
      type: SET_MODEL,
      payload: {
        ...shiftAllocationModel,
        processInfo: updatedProcessInfo,
        boardEntries: updatedBoardEntries,
        clusterDefinitions: updatedClusterDefinitions
      }
    });
  }
};
