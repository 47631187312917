import React from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import { ProcessDetailCard } from './ProcessDetailCard';
import { ClusterDetailCard } from './ClusterDetailCard';
import { ALLOCATION_TYPE, PROCESS_PATHS } from '../data';

export const ProcessPathAllocation = ({ shiftAllocationModel, shiftAllocationDispatch, setIsBoardUpdated }) => {
  const { processInfo, associateInfo, clusterDefinitions } = shiftAllocationModel;
  const renderProcessPathAllocation = () => {
    return processInfo.map((boardEntry) => {
      if (boardEntry.allocationType !== ALLOCATION_TYPE.AISLES && boardEntry.id !== PROCESS_PATHS.BENCH) {
        return (
          <ProcessDetailCard
            key={boardEntry.id}
            boardEntry={boardEntry}
            associateInfo={associateInfo}
            clusterDefinitions={clusterDefinitions}
          />
        );
      }
    });
  };

  const renderStowAllocation = () => {
    return processInfo.map((boardEntry) => {
      if (boardEntry.allocationType === ALLOCATION_TYPE.AISLES) {
        return (
          <ClusterDetailCard
            key={boardEntry.id}
            boardEntry={boardEntry}
            shiftAllocationModel={shiftAllocationModel}
            shiftAllocationDispatch={shiftAllocationDispatch}
            setIsBoardUpdated={setIsBoardUpdated}
          />
        );
      }
    });
  };

  return (
    <Column spacingInset={'none'} spacing="xsmall">
      <Row spacing={'xsmall'} wrap={'down'}>
        {renderProcessPathAllocation()}
      </Row>
      <Row spacing={'xsmall'} wrap={'down'}>
        {renderStowAllocation()}
      </Row>
    </Column>
  );
};
