import React, { useEffect, useState } from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Alert from '@amzn/meridian/alert';
import Toggle from '@amzn/meridian/toggle';
import Text from '@amzn/meridian/text';
import { PROCESS_PATHS } from '../data';

const ARIA_LABELS = {
  training: 'Toggle override training violation',
  jobRotation: 'Toggle override job rotation violation'
};

const VIOLATION_LABELS = {
  training: ({ type }) => (
    <React.Fragment>
      Associate is missing the required training:&nbsp; <i>{type}</i>
    </React.Fragment>
  ),
  jobRotation: ({ type }) => (
    <React.Fragment>
      Associate is prohibited to work as&nbsp;<i>{type}</i>&nbsp;based on job rotation
    </React.Fragment>
  )
};

const ViolationsToggle = ({
  violations = [
    { violationReason: 'training', type: PROCESS_PATHS.STAGE },
    { violationReason: 'jobRotation', type: PROCESS_PATHS.STAGE }
  ],
  onChange = () => null
}) => {
  const [isToggled, setIsToggled] = useState(Array(violations.length).fill(false));

  useEffect(() => {
    onChange(isToggled);
  }, []);

  const handleToggle = (index) => {
    setIsToggled((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      onChange(updated);
      return updated;
    });
  };

  if (violations.length === 0) {
    return null;
  }

  return (
    <Box spacing="200">
      <Alert type="warning" size="medium">
        {violations.map((violation, index) => {
          const LabelComponent = VIOLATION_LABELS[violation.violationReason];
          return (
            <Row key={violation.violationReason}>
              <Text>
                <LabelComponent type={violation.type} />
              </Text>
              <Toggle
                checked={isToggled[index]}
                onChange={() => handleToggle(index)}
                aria-label={ARIA_LABELS[violation.violationReason]}
              >
                <Text color="error">Override</Text>
              </Toggle>
            </Row>
          );
        })}
      </Alert>
    </Box>
  );
};

export default ViolationsToggle;
